import { Group, Select, Text, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import { Timestamp } from "firebase/firestore";
import { notifications } from "@mantine/notifications";
import { spriteIds } from "../../../lists/pokemon/spriteIds";
import "./AdminForm.css";

const TournamentForm = ({ mode }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    let data = [];

    for (const id in spriteIds) {
      data.push({
        label: spriteIds[id],
        value: id,
      });
    }

    data.push({ value: "", label: "None" });

    setList(data);
  }, []);

  const [info, setInfo] = useState({
    location: "",
    type: "",
    totalParticipants: "",
    link: "",
  });
  const [deckFields, setDeckFields] = useState([
    {
      deck: "",
      deckCount: "",
      card1: "",
      card2: "",
      card1Id: "",
      card2Id: "",
      card1Sprite: "",
      card2Sprite: "",
    },
  ]);
  const [date, setDate] = useState("");

  //handle change for info input
  const handleInfoChange = (e, type) => {
    setInfo({ ...info, [type]: e.target.value });
  };

  //handle change for result input
  const handleDeckFieldsChange = (i, e) => {
    let data = [...deckFields];
    data[i][e.target.name] = e.target.value;
    setDeckFields(data);
  };

  const handleSpriteChange = (i, e, sprite) => {
    let data = [...deckFields];
    data[i][sprite] = e;
    setDeckFields(data);
  };

  //handler to add more fields for result
  const addDeckField = () => {
    let newField = {
      deck: "",
      deckCount: "",
      card1: "",
      card2: "",
      card1Id: "",
      card2Id: "",
      card1Sprite: "",
      card2Sprite: "",
    };

    setDeckFields([...deckFields, newField]);
  };

  //handler to del fields for result
  const delDeckField = (i) => {
    const resultToDel = deckFields[i];

    setDeckFields(deckFields.filter((result) => result !== resultToDel));
  };

  //submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const addData = {
      location: info.location,
      type: info.type,
      totalParticipants: info.totalParticipants,
      link: info.link,
      date: Timestamp.fromDate(date).seconds,
      decks: deckFields,
    };

    //fetch total deck count
    let totalDecks = 0;
    addData.decks.forEach((deck) => (totalDecks += Number(deck.deckCount)));

    if (Number(addData.totalParticipants) > totalDecks) {
      notifications.show({
        title: "Error",
        message: "Total participants exceeded deck counts",
        color: "red",
      });
    } else if (Number(addData.totalParticipants) < totalDecks) {
      notifications.show({
        title: "Error",
        message: "Deck counts exceeded total participants",
        color: "red",
      });
    }
  };

  return (
    <div className="new-div">
      {/* <DayDate unix={Timestamp.fromDate(date).seconds} /> */}
      <form className="new-form" onSubmit={(e) => handleSubmit(e)}>
        <Group position="center">
          <Select
            label="Tournament Type"
            value={info.type}
            onChange={(e) => setInfo({ ...info, type: e })}
            data={[
              { label: "Championship", value: "championship" },
              { label: "Regional League", value: "regional" },
              { label: "Town League", value: "town" },
              { label: "Gym Battle", value: "gym" },
            ]}
            required
          />
          <TextInput
            label="Location"
            value={info.location}
            onChange={(e) => handleInfoChange(e, "location")}
            className="add-new-input"
            required
          />
          <TextInput
            label="Link"
            value={info.link}
            onChange={(e) => handleInfoChange(e, "link")}
            className="add-new-input"
            required
          />{" "}
          <TextInput
            label="Total Participants"
            value={info.totalParticipants}
            onChange={(e) => handleInfoChange(e, "totalParticipants")}
            className="add-new-input"
            required
            type="number"
          />
        </Group>
        <div className="new-date-div">
          <Text>Date</Text>
          <DatePicker
            locale="my"
            className="new-date"
            value={date}
            onChange={setDate}
            required
          />
        </div>
        <div className="new-result-div">
          {deckFields.map((result, i) => (
            <div className="new-dynamic-field-div" key={i}>
              <Text className="new-section-title">Deck {i + 1}</Text>
              <div key={i}>
                <Group justify="flex-start" className="new-dynamic-field-grp">
                  <TextInput
                    name="deck"
                    label="Deck"
                    value={result.deck}
                    onChange={(e) => handleDeckFieldsChange(i, e)}
                    className="add-new-input dynamic"
                    required
                  />
                  <TextInput
                    name="deckCount"
                    label="Deck Count"
                    value={result.deckCount}
                    onChange={(e) => handleDeckFieldsChange(i, e)}
                    className="add-new-input dynamic"
                    type="number"
                    required
                  />
                </Group>
                <Group justify="flex-start" className="new-dynamic-field-grp">
                  <TextInput
                    name="card"
                    label="Card 1"
                    value={result.card1}
                    onChange={(e) => handleDeckFieldsChange(i, e)}
                    className="add-new-input dynamic"
                    required
                  />
                  <TextInput
                    name="card1Id"
                    label="Card 1 ID"
                    value={result.card1Id}
                    onChange={(e) => handleDeckFieldsChange(i, e)}
                    className="add-new-input dynamic"
                    required
                  />
                  <Select
                    name="card1Sprite"
                    label="Card 1 Sprite"
                    value={result.card1Sprite}
                    onChange={(e) => handleSpriteChange(i, e, "card1Sprite")}
                    data={list}
                    searchable
                    required
                  />
                </Group>
                <Group justify="flex-start" className="new-dynamic-field-grp">
                  <TextInput
                    name="card2"
                    label="Card 2"
                    placeholder="Leave blank for none"
                    value={result.card2}
                    onChange={(e) => handleDeckFieldsChange(i, e)}
                    className="add-new-input dynamic"
                  />
                  <TextInput
                    name="card2Id"
                    label="Card 2 ID"
                    placeholder="Leave blank for none"
                    value={result.card2Id}
                    onChange={(e) => handleDeckFieldsChange(i, e)}
                    className="add-new-input dynamic"
                  />
                  <Select
                    name="card1Sprite"
                    label="Card 2 Sprite"
                    value={result.card2Sprite}
                    placeholder="Leave blank for none"
                    onChange={(e) => handleSpriteChange(i, e, "card2Sprite")}
                    data={list}
                    searchable
                  />
                </Group>
                <Group justify="center">
                  {i === deckFields.length - 1 ? (
                    <IconCirclePlus
                      color="green"
                      className="new-dynamic-field-add-del"
                      onClick={addDeckField}
                    />
                  ) : (
                    ""
                  )}
                  {i === 0 ? (
                    ""
                  ) : (
                    <IconCircleMinus
                      color="red"
                      className="new-dynamic-field-add-del"
                      onClick={() => delDeckField(i)}
                    />
                  )}
                </Group>
              </div>
            </div>
          ))}
        </div>
        <Button className="new-submit-btn" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default TournamentForm;
