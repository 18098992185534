const imgBaseUrl =
  "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Fstates%2F";

export const statesSelectList = [
  {
    label: "All States",
    value: "All States",
  },
  {
    label: "Selangor",
    value: "Selangor",
    image: `${imgBaseUrl}sel.png?alt=media`,
  },
  {
    label: "Kuala Lumpur",
    value: "Kuala Lumpur",
    image: `${imgBaseUrl}kl.png?alt=media`,
  },
  {
    label: "Penang",
    value: "Penang",
    image: `${imgBaseUrl}pen.png?alt=media`,
  },
  {
    label: "Melaka",
    value: "Melaka",
    image: `${imgBaseUrl}mel.png?alt=media`,
  },
  {
    label: "Johor",
    value: "Johor",
    image: `${imgBaseUrl}joh.png?alt=media`,
  },
  {
    label: "Negeri Sembilan",
    value: "Negeri Sembilan",
    image: `${imgBaseUrl}sem.png?alt=media`,
  },
  {
    label: "Perak",
    value: "Perak",
    image: `${imgBaseUrl}per.png?alt=media`,
  },
  {
    label: "Kedah",
    value: "Kedah",
    image: `${imgBaseUrl}ked.png?alt=media`,
  },
];
