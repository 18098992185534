import { Button, Card, Group, Text, Title } from "@mantine/core";
import "./HomeTopMobile.css";

const HomeTopMobile = () => {
  return (
    <div className="home-top-m-div">
      <Card className="home-top-m-card" radius="lg">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fmart.webp?alt=media"
          alt="bulbasaur"
          className="home-top-m-img"
        />
        <div className="home-top-m-card-content">
          <Title className="home-top-m-card-title">Stores</Title>
          <Text className="home-top-m-card-text">
            Our list of TCG and board games retailers, with information, in
            Malaysia
          </Text>
          <Group justify="center">
            <a>
              <Button className="home-top-m-card-btn">
                Check out our list now
              </Button>
            </a>
          </Group>
        </div>
      </Card>
      <Card className="home-top-m-card" radius="lg">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fred_pikachu.png?alt=media"
          alt="bulbasaur"
          className="home-top-m-img"
        />
        <div className="home-top-m-card-content">
          <Title className="home-top-m-card-title">Articles</Title>
          <Text className="home-top-m-card-text">
            Analyses, interviews, and snippets on the recent Pokémon TCG news
          </Text>
          <Group justify="center">
            <a>
              <Button className="home-top-m-card-btn">
                Head to articles section
              </Button>
            </a>
          </Group>
        </div>
      </Card>
      <Card className="home-top-m-card" radius="lg">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fgym.png?alt=media"
          alt="bulbasaur"
          className="home-top-m-img"
        />
        <div className="home-top-m-card-content">
          <Title className="home-top-m-card-title">Stores</Title>
          <Text className="home-top-m-card-text">
            Check out the data on past and upcoming Pokémon TCG tournaments in
            Malaysia
          </Text>
          <Group justify="center">
            <a>
              <Button className="home-top-m-card-btn">Take me there</Button>
            </a>
          </Group>
        </div>
      </Card>
    </div>
  );
};

export default HomeTopMobile;
