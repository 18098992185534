import { Box, Card, Group, Text, Title } from "@mantine/core";

import "./HomeCards.css";

const HomeCards = () => {
  return (
    <div className="home-cards-div">
      <Group justify="center" gap="xl" id="home-cards-grp">
        <a className="basic-anchor home-cards" href="/stores">
          <Card className="home-card">
            <Card.Section className="home-card-section">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/adhoc%2Fpokemon%2FPokemart_in_the_Johto_Region.webp?alt=media"
                alt="playmat"
                className="home-card-img"
              />
              <div className="home-card-rect"></div>
            </Card.Section>
            <Title className="home-card-title">Stores</Title>
            <Text className="home-card-text">
              Our list of TCG and board games retailers in Malaysia
            </Text>
          </Card>
        </a>
        <a className="basic-anchor home-cards" href="/tournaments">
          <Card className="home-card">
            <Card.Section className="home-card-section">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/adhoc%2Fpokemon%2Fplaymat.jpg?alt=media"
                alt="playmat"
                className="home-card-img"
              />
              <div className="home-card-rect"></div>
            </Card.Section>
            <Box className="home-card-content" justify="center">
              <Title className="home-card-title">Tournaments</Title>
              <Text className="home-card-text">
                Check out the data on past and upcoming Pokémon TCG tournaments
                in Malaysia
              </Text>
            </Box>
          </Card>
        </a>
        <a className="basic-anchor home-cards" href="/articles">
          <Card className="home-card">
            <Card.Section className="home-card-section">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/adhoc%2Fpokemon%2Farticles.jpeg?alt=media"
                alt="playmat"
                className="home-card-img"
              />
              <div className="home-card-rect"></div>
            </Card.Section>
            <Title className="home-card-title">Articles</Title>
            <Text className="home-card-text">
              Analyses, interviews, and snippets on the recent Pokémon TCG news
            </Text>
          </Card>
        </a>
      </Group>
    </div>
  );
};

export default HomeCards;
