import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithPopup,
  linkWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyChDrZEkCJBbXqp_o-HguUAjrEQABQF1Ps",
  authDomain: "timmycards.firebaseapp.com",
  projectId: "timmycards",
  storageBucket: "timmycards-website-public",
  messagingSenderId: "1006835614597",
  appId: "1:1006835614597:web:9ad90389bb0cb1129e3aec",
  measurementId: "G-MBMRDRGNPZ",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const storage = getStorage(app);

const obtainUserDoc = (user) => doc(db, "users", user?.uid);

const obtainUserPrivateDoc = (user) =>
  doc(db, `users/${user?.uid}/private`, "info");

const loginWithPopup = async (provider) => {
  try {
    const res = await setPersistence(auth, browserLocalPersistence).then(() =>
      signInWithPopup(auth, provider)
    );
    const user = res.user;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      signOut(auth);
      alert("Please register first!");
      window.location.reload();
    }
    window.location.reload();
  } catch (err) {
    console.error(err.message);
  }
};

const loginWithGoogle = () => {
  loginWithPopup(googleProvider);
};

const loginWithFacebook = () => {
  loginWithPopup(facebookProvider);
};

const loginWithEmail = async (email, password) => {
  try {
    await setPersistence(auth, browserLocalPersistence).then(() =>
      signInWithEmailAndPassword(auth, email, password)
    );
    window.location.reload();
  } catch (err) {
    console.error(err.message);
    alert("E-mail or password incorrect");
  }
};

const linkGoogle = async () => {
  try {
    const res = await linkWithPopup(auth.currentUser, googleProvider);
    const user = res.user;
    const docRef = obtainUserDoc(user);
    const docSnap = await getDoc(docRef);
    await updateDoc(obtainUserDoc(user), {
      authProvider: docSnap.data().authProvider.concat("google"),
    });
  } catch (err) {
    console.error(err.message);
    alert("Google Account is already in use");
  }
};

const linkFacebook = async () => {
  try {
    const res = await linkWithPopup(auth.currentUser, facebookProvider);
    const user = res.user;
    const docRef = obtainUserDoc(user);
    const docSnap = await getDoc(docRef);
    await updateDoc(obtainUserDoc(user), {
      authProvider: docSnap.data().authProvider.concat("facebook"),
    });
  } catch (err) {
    console.error(err.message);
    alert("Facebook Account is already in use");
  }
};

const registerWithPopup = async (provider, authProvider) => {
  try {
    const res = await setPersistence(auth, browserLocalPersistence).then(() =>
      signInWithPopup(auth, provider)
    );
    const user = res.user;
    const docRef = obtainUserDoc(user);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      await setDoc(obtainUserDoc(user), {
        authProvider: [authProvider],
        creationTime: user.metadata.creationTime,
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Fuser.png?alt=media",
      });
      await setDoc(obtainUserPrivateDoc(user), {
        email: user.email,
      });
      window.location.href = "/linkEmail";
    } else if (docSnap.exists()) {
      alert("User has already been registered!");
    }
  } catch (err) {
    console.error(err.message);
  }
};

const registerWithGoogle = () => {
  registerWithPopup(googleProvider, "google");
};

const registerWithFacebook = () => {
  registerWithPopup(facebookProvider, "facebook");
};

const emailLinkCredential = (email, password) => {
  return EmailAuthProvider.credential(email, password);
};

const linkEmail = async (credential) => {
  try {
    const res = await linkWithCredential(auth.currentUser, credential);
    const user = res.user;
    const docRef = obtainUserDoc(user);
    const docSnap = await getDoc(docRef);
    await updateDoc(obtainUserDoc(user), {
      authProvider: docSnap.data().authProvider.concat("email"),
    });
  } catch (err) {
    console.error(err.message);
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(obtainUserDoc(user), {
      authProvider: ["email"],
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Fuser.png?alt=media",
    });
    await setDoc(obtainUserPrivateDoc(user), {
      email,
    });
    window.location.href = "/additionalInfo";
  } catch (err) {
    console.error(err.message);
  }
};

const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const uploadUserImg = async (img, name) => {
  const storageRef = ref(storage, `Users/Photo/${name}`);

  await uploadBytes(storageRef, img);

  return (await getDownloadURL(storageRef)).split("&token")[0];
};

export {
  auth,
  db,
  loginWithGoogle,
  loginWithFacebook,
  loginWithEmail,
  registerWithGoogle,
  registerWithFacebook,
  registerWithEmailAndPassword,
  resetPassword,
  logout,
  googleProvider,
  facebookProvider,
  obtainUserDoc,
  obtainUserPrivateDoc,
  emailLinkCredential,
  linkEmail,
  linkGoogle,
  linkFacebook,
  uploadUserImg,
};
