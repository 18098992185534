import pkmIcon from "./icons/pkm.png";
import dgmIcon from "./icons/dgm.png";
import warIcon from "./icons/40k.png";
import bdIcon from "./icons/bd.png";
import boardIcon from "./icons/board.png";
import bsIcon from "./icons/bs.png";
import dndIcon from "./icons/d&d.png";
import dmIcon from "./icons/dm.png";
import fnbIcon from "./icons/f&b.png";
import mtgIcon from "./icons/mtg.png";
import opIcon from "./icons/op.png";
import svIcon from "./icons/sv.png";
import vgIcon from "./icons/vg.png";
import wsIcon from "./icons/ws.png";
import ygoIcon from "./icons/ygo.png";
import gaIcon from "./icons/ga.png";

export const gamesSelectList = [
  {
    label: "All Games",
    value: "All Games",
  },
  {
    label: "Pokemon",
    value: "PKM",
    image: pkmIcon,
  },
  {
    label: "Digimon",
    value: "DGM",
    image: dgmIcon,
  },
  {
    label: "Yu-Gi-Oh",
    value: "YGO",
    image: ygoIcon,
  },
  {
    label: "One Piece",
    value: "OP",
    image: opIcon,
  },
  {
    label: "Magic the Gathering",
    value: "MTG",
    image: mtgIcon,
  },
  {
    label: "Flesh & Blood",
    value: "F&B",
    image: fnbIcon,
  },
  {
    label: "Vanguard",
    value: "VG",
    image: vgIcon,
  },
  {
    label: "Weiss Schwarz",
    value: "WS",
    image: wsIcon,
  },
  {
    label: "Duel Masters",
    value: "DM",
    image: dmIcon,
  },
  {
    label: "Build Divide",
    value: "BD",
    image: bdIcon,
  },
  {
    label: "Battle Spirit",
    value: "BS",
    image: bsIcon,
  },
  {
    label: "Shadowverse",
    value: "SV",
    image: svIcon,
  },
  {
    label: "Dungeons & Dragons",
    value: "D&D",
    image: dndIcon,
  },
  {
    label: "Grand Archive",
    value: "GA",
    image: gaIcon,
  },
  {
    label: "Warhammer",
    value: "40K",
    image: warIcon,
  },
  {
    label: "Board Games",
    value: "Board",
    image: boardIcon,
  },
];
