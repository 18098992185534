import { Group, List, Table, Title, Tooltip } from "@mantine/core";

import getEnergyLogo from "../../functions/pokemon/getEnergyLogo";
import EnergyTooltip from "../games/pokemon/EnergyTooltip";
import "./CardInfo.css";
import CardsetName from "../games/pokemon/CardsetName";

const CardInfo = ({ card }) => {
  const type = card.supertype;

  //render pokemon table
  const pkmTable = () => {
    return (
      <Table.Tbody>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Type</Table.Td>
          <Table.Td className="card-info-table-body">
            <EnergyTooltip energy={card?.types} name="card-info-energy" />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">HP</Table.Td>
          <Table.Td className="card-info-table-body">{card?.hp}</Table.Td>
        </Table.Tr>
        {card?.rules ? (
          <Table.Tr>
            <Table.Td className="card-info-table-head">Rules</Table.Td>
            <Table.Td className="card-info-table-body">
              <List>
                {card.rules?.map((rule, i) => (
                  <List.Item key={i} className="card-info-list-item">
                    {rule}
                  </List.Item>
                ))}
              </List>
            </Table.Td>
          </Table.Tr>
        ) : null}
        <Table.Tr>
          <Table.Td className="card-info-table-head">Weakness</Table.Td>
          <Table.Td className="card-info-table-body">
            {card.weaknesses
              ? card.weaknesses?.map((weakness, i) => (
                  <Group key={i}>
                    <Tooltip label={weakness?.type}>
                      <img
                        src={getEnergyLogo(weakness.type?.toLowerCase())}
                        alt="energy"
                        className="card-info-energy"
                      />
                    </Tooltip>
                    {weakness?.value}
                  </Group>
                ))
              : "None"}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Resistance</Table.Td>
          <Table.Td className="card-info-table-body">
            {card.resistances
              ? card.resistances?.map((resistance, i) => (
                  <Group key={i}>
                    <Tooltip label={resistance?.type}>
                      <img
                        src={getEnergyLogo(resistance.type?.toLowerCase())}
                        alt="energy"
                        className="card-info-energy"
                      />
                    </Tooltip>
                    {resistance?.value}
                  </Group>
                ))
              : "None"}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Retreat</Table.Td>
          <Table.Td className="card-info-table-body">
            <EnergyTooltip energy={card?.retreatCost} name="card-info-energy" />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Artist</Table.Td>
          <Table.Td className="card-info-table-body">{card?.artist}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Cardset</Table.Td>
          <Table.Td className="card-info-table-body">
            <CardsetName cardset={card?.cardset} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Rarity</Table.Td>
          <Table.Td className="card-info-table-body">{card?.rarity}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    );
  };

  const trainerTable = () => {
    return (
      <Table.Tbody>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Type</Table.Td>
          <Table.Td className="card-info-table-body">
            <List>
              {card.subtypes?.map((type, i) => (
                <List.Item key={i} className="card-info-list-item">
                  {type}
                </List.Item>
              ))}
            </List>
          </Table.Td>
        </Table.Tr>
        {!card.rules[1] ? null : (
          <Table.Tr>
            <Table.Td className="card-info-table-head">Rule</Table.Td>
            <Table.Td className="card-info-table-body">
              {card.rules[1]}
            </Table.Td>
          </Table.Tr>
        )}
        <Table.Tr>
          <Table.Td className="card-info-table-head">Artist</Table.Td>
          <Table.Td className="card-info-table-body">{card?.artist}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Cardset</Table.Td>
          <Table.Td className="card-info-table-body">
            <CardsetName cardset={card?.cardset} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Rarity</Table.Td>
          <Table.Td className="card-info-table-body">{card?.rarity}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    );
  };

  const energyTable = () => {
    return (
      <Table.Tbody>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Type</Table.Td>
          <Table.Td className="card-info-table-body">
            <List>
              {card.subtypes?.map((type, i) => (
                <List.Item key={i} className="card-info-list-item">
                  {type}
                </List.Item>
              ))}
            </List>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Artist</Table.Td>
          <Table.Td className="card-info-table-body">{card?.artist}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Cardset</Table.Td>
          <Table.Td className="card-info-table-body">
            <CardsetName cardset={card?.cardset} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td className="card-info-table-head">Rarity</Table.Td>
          <Table.Td className="card-info-table-body">{card?.rarity}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    );
  };

  const tableRender = () => {
    switch (type) {
      case "Pokémon":
        return pkmTable();
      case "Trainer":
        return trainerTable();
      case "Energy":
        return energyTable();
      default:
        return null;
    }
  };

  return (
    <div className="card-info-div">
      <Title className="card-id-name">
        #{card?.number} - {card?.name}
      </Title>
      <Table
        highlightOnHover
        withColumnBorders
        withTableBorder
        className="card-t"
      >
        {tableRender()}
      </Table>
    </div>
  );
};

export default CardInfo;
