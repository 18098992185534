import { useState, useEffect } from "react";

import contentService from "../../../services/content";

const CardsetName = ({ cardset }) => {
  const [cardsets, setCardsets] = useState([]);

  useEffect(() => {
    const params = {
      card_type: "pokemon",
      language: "en",
    };

    contentService
      .get({ route: "cardsets/get-all", params: params })
      .then((res) => setCardsets(res));
  }, []);

  const selectedCardset = cardsets?.find((res) => res.id === cardset);

  return <div>{selectedCardset?.name}</div>;
};

export default CardsetName;
