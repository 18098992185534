import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Grid, Group, Loader, Title } from "@mantine/core";

import CardCard from "../../components/cards/CardCard";
import contentService from "../../services/content";

import "./Cardset.css";

const Cardset = () => {
  //fetch cardset id
  const uid = useParams().id;

  const [cardset, setCardset] = useState([]);
  const [cardsetList, setCardsetList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [page, setPage] = useState(1);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const lastItemRef = useRef(null);

  //fetch cardset info
  useEffect(() => {
    const params = {
      card_type: "pokemon",
      language: "en",
    };

    try {
      contentService
        .get({ route: `cardsets/${uid}`, params: params })
        .then((res) => setCardset(res));
    } catch (err) {
      console.error(err.message);
    }
  }, [uid]);

  //fetch cardset list
  useEffect(() => {
    const params = {
      card_type: "pokemon",
      language: "en",
      page,
    };

    const fetchCardset = () => {
      try {
        contentService
          .get({ route: `cards/get-by-cardset-id/${uid}`, params: params })
          .then((res) => {
            if (res?.length > 0) {
              setCardsetList([...cardsetList, ...res]);
              setLastLoadedPage(lastLoadedPage + 1);
              setIsLoaded(true);
            } else {
              setFullyLoaded(true);
            }
          });
      } catch (err) {
        console.error(err.message);
      }
    };

    if (lastLoadedPage < page) {
      fetchCardset();
    }
  }, [page, cardsetList, lastLoadedPage, setIsLoaded, uid]);

  // observer for last item
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (isLoaded && entry.isIntersecting && !fullyLoaded) {
          setTimeout(() => {
            setPage((page) => page + 1);
            setIsLoaded(false);
          }, 1000);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.7,
      }
    );
    // set target ref
    const currentTarget = lastItemRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [isLoaded, lastItemRef, setPage, setIsLoaded, fullyLoaded]);

  //render cards
  let allCards = [];
  cardsetList.forEach((card, i) => {
    let cardCard = <CardCard card={card} key={i} />;
    allCards.push(cardCard);
  });

  return (
    <div className="page-div">
      <Group justify="center">
        <img src={cardset.images?.logo} alt="cardset-logo" id="cardset-logo" />
      </Group>
      <Title className="page-title">{cardset?.name}</Title>
      <div className="cardset-cards-group">{allCards}</div>
      <div ref={lastItemRef} className="page-loader">
        {!fullyLoaded && <Loader color="dark" variant="dots" size="xl" />}
      </div>
    </div>
  );
};

export default Cardset;
