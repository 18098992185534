import { useEffect, useRef } from "react";
import "./Ads.css";

export const BannerAd300 = () => {
  const key = "d439379b93df28ae7450d75162acec90";

  const banner = useRef();

  useEffect(() => {
    const atOptions = {
      key: key,
      format: "iframe",
      height: 50,
      width: 320,
      params: {},
    };

    const conf = document.createElement("script");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `//www.topcreativeformat.com/${key}/invoke.js`;
    conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

    banner.current.append(conf);
    banner.current.append(script);
    // }
  }, [banner]);

  return <div ref={banner}></div>;
};

export const BannerAd728 = () => {
  const key = "dba411a814af0dd7fcb65ade6fe87cd6";

  const banner = useRef();

  useEffect(() => {
    const atOptions = {
      key,
      format: "iframe",
      height: 90,
      width: 728,
      params: {},
    };

    const conf = document.createElement("script");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `//www.topcreativeformat.com/${key}/invoke.js`;
    conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

    banner.current.append(conf);
    banner.current.append(script);
    // }
  }, [banner]);

  return <div ref={banner}></div>;
};

export const NativeAds = () => {
  const key = "9310b1523a82ab9f329b3674d0f393ac";

  const banner = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `//pl21579537.toprevenuegate.com/${key}/invoke.js`;
    script.setAttribute("async", true);
    script.setAttribute("data-cfasync", false);

    banner.current.append(script);
    // }
  }, [banner]);

  return (
    <div
      className="asterra-native mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={banner}
      id={`container-${key}`}
      style={{ maxWidth: "1280px" }}
    ></div>
  );
};
