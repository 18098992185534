import { Card, Grid, Text } from "@mantine/core";

import "./ArticlesCard.css";

const ArticlesCard = ({ article }) => {
  return (
    <a
      className="basic-anchor article-card"
      href={`/articles/article/${article.uid}`}
    >
      <Card className="articles-card" padding="md" radius="md" shadow="md">
        <Card.Section>
          <img src={article.imgURL} alt="card" className="articles-card-img" />
        </Card.Section>
        <Text className="articles-card-title">{article.title}</Text>
        <Text className="aritcles-card-subtitle" lineClamp={2}>
          {article.subtitle}
        </Text>
      </Card>
    </a>
  );
};

export default ArticlesCard;
