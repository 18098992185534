const baseUrl = (type) =>
  `https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2FpkmEnergy%2F${type}.PNG?alt=media`;

const getEnergyLogo = (energy) => {
  switch (energy) {
    case "fire":
      return baseUrl("fire");
    case "colorless":
      return baseUrl("colorless");
    case "darkness":
      return baseUrl("darkness");
    case "grass":
      return baseUrl("grass");
    case "lightning":
      return baseUrl("lightning");
    case "metal":
      return baseUrl("metal");
    case "psychic":
      return baseUrl("psychic");
    case "fighting":
      return baseUrl("fighting");
    case "water":
      return baseUrl("water");
    case "dragon":
      return baseUrl("dragon");
    case "fairy":
      return baseUrl("fairy");
    default:
      break;
  }
};

export default getEnergyLogo;
