import AboutContent from "../../components/about/AboutContent";

const About = () => {
  return (
    <div className="page-div">
      <AboutContent />
    </div>
  );
};

export default About;
