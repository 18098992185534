import { Group, Input } from "@mantine/core";
import "./GenericSearch.css";
import { IconArrowNarrowRight, IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const GenericSearch = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const handleSearch = () => {
    return navigate(`/search/${search}/all`);
  };

  return (
    <div className="generic-search-div">
      <form onSubmit={handleSearch}>
        <Group>
          <Input
            leftSection={<IconSearch />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Timmy Cards"
            className="generic-search-input"
            radius="xl"
            rightSection={
              <IconArrowNarrowRight
                className="generic-search-arrow"
                type="submit"
                onClick={handleSearch}
              />
            }
          />
        </Group>
      </form>
    </div>
  );
};

export default GenericSearch;
