import { Card, Grid, Text } from "@mantine/core";
import "./CardCard.css";

const CardCard = ({ card }) => {
  return (
    // <Grid.Col span="auto">
    <a className="basic-anchor" href={`/card/${card?.uid}`}>
      <Card className="card-card" radius="lg">
        <Card.Section>
          <img src={card?.images} alt="card" className="card-card-img" />
          <Text className="card-card-text">
            {card?.cardset?.toUpperCase()} <br /> #{card?.number} - {card?.name}
          </Text>
        </Card.Section>
      </Card>
    </a>
    // </Grid.Col>
  );
};

export default CardCard;
