import { Title } from "@mantine/core";
import EditProfile from "../../components/user/EditProfile";

const EditProfilePage = ({ user, userDoc, userPrivateDoc }) => {
  return (
    <div className="page-div">
      <Title className="page-title">Edit Profile</Title>
      <EditProfile
        user={user}
        userDoc={userDoc}
        userPrivateDoc={userPrivateDoc}
      />
    </div>
  );
};

export default EditProfilePage;
