import "./Top.css";
import { IconChevronCompactUp } from "@tabler/icons-react";
import useScrollDirection from "../../hooks/useScrollDirection";
import { Slide } from "react-awesome-reveal";

const Top = () => {
  const scrollDirection = useScrollDirection();

  return (
    <div
      className={`top-div ${scrollDirection === "up" ? "show" : "hide"}`}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <IconChevronCompactUp size="15" color="black" className="top-up" />
      <img
        src="https://img.pokemondb.net/sprites/x-y/normal/pikachu-f.png"
        alt="pikachu"
        className="top-img"
      />
    </div>
  );
};

export default Top;
