import { useState } from "react";
import {
  registerWithEmailAndPassword,
  registerWithGoogle,
  registerWithFacebook,
} from "../../firebase";
import { Input, Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAt, IconPassword, IconEdit } from "@tabler/icons-react";

import "./UserAuth.css";

const Register = ({ setComp, googleIcon, facebookIcon }) => {
  const [credentials, setCreadentials] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });

  //registerhandler
  const handleRegister = (e) => {
    e.preventDefault();

    if (!credentials.email || !credentials.confirmEmail) {
      notifications.show({
        title: "Error",
        message: "Please enter a valid e-mail",
        color: "red",
      });
    } else if (credentials.email !== credentials.confirmEmail) {
      notifications.show({
        title: "Error",
        message: "The two e-mail addressed do not match",
        color: "red",
      });
    } else if (!credentials.password || !credentials.confirmPassword) {
      notifications.show({
        title: "Error",
        message: "Please enter your password",
        color: "red",
      });
    } else if (!credentials.password !== !credentials.confirmPassword) {
      notifications.show({
        title: "Error",
        message: "The two passwords do not match",
        color: "red",
      });
    } else if (
      credentials.password.length < 8 ||
      credentials.password.length > 16
    ) {
      notifications.show({
        title: "Error",
        message: "Password must be between 8 to 16 characters",
        color: "red",
      });
    } else {
      registerWithEmailAndPassword(credentials.email, credentials.password);
    }
  };

  //check to disable register button if credentials are not found
  const registerBtnDisabled = () => {
    if (
      !credentials.email ||
      !credentials.password ||
      !credentials.confirmEmail ||
      !credentials.confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="user-auth-body">
      <IconEdit color="#f05454" size="50px" />
      <h2 className="user-auth-title">Register</h2>
      <form onSubmit={(e) => handleRegister(e)}>
        <Input
          placeholder="E-mail Address"
          value={credentials.email}
          onChange={(e) =>
            setCreadentials({ ...credentials, email: e.target.value })
          }
          type="email"
          required
          className="user-auth-input"
          leftSection={<IconAt />}
        />
        <Input
          placeholder="Confirm E-mail Address"
          value={credentials.confirmEmail}
          onChange={(e) =>
            setCreadentials({ ...credentials, confirmEmail: e.target.value })
          }
          type="email"
          required
          className="user-auth-input"
          leftSection={<IconAt />}
        />
        <Input
          placeholder="Password"
          value={credentials.password}
          onChange={(e) =>
            setCreadentials({ ...credentials, password: e.target.value })
          }
          type="password"
          required
          className="user-auth-input"
          leftSection={<IconPassword />}
        />{" "}
        <Input
          placeholder="Confirm Password"
          value={credentials.confirmPassword}
          onChange={(e) =>
            setCreadentials({ ...credentials, confirmPassword: e.target.value })
          }
          type="password"
          required
          className="user-auth-input"
          leftSection={<IconPassword />}
        />
        <Button
          type="submit"
          className="user-auth-email-btn"
          disabled={registerBtnDisabled()}
          fullWidth
        >
          Register
        </Button>
      </form>
      <p className="user-auth-or">or</p>
      <Button
        className="user-auth-google-btn"
        leftSection={googleIcon}
        onClick={registerWithGoogle}
        fullWidth
      >
        Register with Google
      </Button>
      <Button
        className="user-auth-facebook-btn"
        leftSection={facebookIcon}
        onClick={registerWithFacebook}
        fullWidth
      >
        Register with Facebook
      </Button>
      <p className="user-auth-switch" onClick={() => setComp("login")}>
        Already have an account? Login here
      </p>
    </div>
  );
};

export default Register;
