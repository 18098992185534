import { useEffect, useState } from "react";
import contentService from "../../services/content";
import { Card, Group, Input, Title } from "@mantine/core";

import "./Cardsets.css";
import { IconSearch } from "@tabler/icons-react";

const Cardsets = () => {
  const [cardsets, setCardsets] = useState([]);
  const [search, setSearch] = useState("");
  //fetch all cardsets
  useEffect(() => {
    const params = {
      card_type: "pokemon",
      language: "en",
    };

    try {
      contentService
        .get({ route: "cardsets/get-all", params: params })
        .then((res) => setCardsets(res.reverse()));
    } catch (err) {
      console.error(err.message);
    }
  }, []);

  //handler for clicking cardset
  // const handleCardsetClick = (cardset) => {
  //   return (window.location.href = `cardsets/cardset/${cardset?.id}`);
  // };

  //handler for search
  const searchResult = () => {
    if (!search) {
      return cardsets;
    } else if (search) {
      return cardsets?.filter((cardset) =>
        cardset.name.toLowerCase().includes(search.toLowerCase())
      );
    }
  };

  return (
    <div className="page-div">
      <Title className="page-title">Pokemon TCG Cardsets</Title>
      <Input
        leftSection={<IconSearch />}
        placeholder="Search for cardsets"
        radius="xl"
        size="md"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="stores-search-bar"
      />
      <div justify="center" className="cardsets-card-group">
        {searchResult()?.map((cardset, i) => (
          <a href={`cardsets/cardset/${cardset?.id}`} className="basic-anchor">
            <Card
              key={i}
              className="cardsets-card"
              radius="md"
              shadow="xl"
              // onClick={() => handleCardsetClick(cardset)}
            >
              <Card.Section>
                <img
                  src={cardset.images?.logo}
                  alt="cardset-logo"
                  className="cardsets-logo"
                />
              </Card.Section>
              <div className="cardsets-name-div">
                <Title className="cardsets-name">{cardset?.name}</Title>
              </div>
            </Card>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Cardsets;
