import "./Articles.css";
import { Title } from "@mantine/core";
import AllBlogs from "../../components/articles/AllBlogs";
import { IconCirclePlus } from "@tabler/icons-react";
// import { NativeAds } from "../../components/ads/Adsterra/Ads";

const Articles = ({ userDoc }) => {
  return (
    <div className="page-div">
      {/* <NativeAds /> */}
      <Title className="page-title">Articles</Title>
      {userDoc?.role === "admin" ? (
        <a href="/new/article" className="basic-anchor">
          <IconCirclePlus
            size="40"
            color="red"
            className="admin-new-btn"
            // onClick={() => (window.location.href = "/new/article")}
          />
        </a>
      ) : (
        ""
      )}
      {/* <Tabs
        defaultValue="blogs"
        className="articles-tabs"
        styles={() => ({
          tab: {
            "&[data-active]": {
              fontWeight: "800",
              color: "white",
              backgroundColor: "#30475e",
              border: "none",
            },
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab
            value="blogs"
            icon={<IconArticle />}
            className="articles-tab"
          >
            Blogs
          </Tabs.Tab>
          <Tabs.Tab
            value="results"
            icon={<IconAwardFilled />}
            className="articles-tab"
          >
            Tournament Results
          </Tabs.Tab>
        </Tabs.List>
        <div className="articles-tabs-panels">
          <Tabs.Panel value="blogs">
            <AllBlogs />
          </Tabs.Panel>
          <Tabs.Panel value="results">Coming Soon</Tabs.Panel>
        </div>
      </Tabs> */}
      <div className="articles-tabs-panels">
        <AllBlogs />
      </div>
    </div>
  );
};

export default Articles;
