import { Button, Loader, TextInput } from "@mantine/core";
import { useState } from "react";
import contentService from "../../../../services/content";
import { notifications } from "@mantine/notifications";

const AddDeck = ({ closeModal, setModalToShow }) => {
  const [createDeck, setCreateDeck] = useState({
    deck_name: "",
    card1_id: "",
    card2_id: "",
    card3_id: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  //handle deck create btn
  const deckCreate = (e) => {
    e.preventDefault();

    const deckInfo = createDeck;

    setSubmitLoading(true);

    try {
      contentService
        .create({
          route: "decks",
          newObject: deckInfo,
        })
        .then((res) => {
          setSubmitLoading(false);
          notifications.show({
            title: "Success",
            color: "green",
            message: `${deckInfo.deck_name} has been created`,
          });
        })
        .then(() => {
          setCreateDeck({
            deck_name: "",
            card1_id: "",
            card2_id: "",
            card3_id: "",
          });
          closeModal();
          setModalToShow("");
        })
        .catch((err) => console.error(err.message));
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="new-div">
      <form onSubmit={deckCreate} className="new-form">
        <TextInput
          label="Deck Name"
          value={createDeck.deck_name}
          onChange={(e) =>
            setCreateDeck({ ...createDeck, deck_name: e.target.value })
          }
          className="new-input long"
          required
        />
        <TextInput
          label="Card 1 ID"
          value={createDeck.card1_id}
          onChange={(e) =>
            setCreateDeck({ ...createDeck, card1_id: e.target.value })
          }
          className="new-input long"
          required
        />
        <TextInput
          label="Card 2 ID"
          value={createDeck.card2_id}
          onChange={(e) =>
            setCreateDeck({ ...createDeck, card2_id: e.target.value })
          }
          className="new-input long"
        />
        <TextInput
          label="Card 3 ID"
          value={createDeck.card3_id}
          onChange={(e) =>
            setCreateDeck({ ...createDeck, card3_id: e.target.value })
          }
          className="new-input long"
        />
        {!submitLoading ? (
          <Button type="submit" className="new-submit-btn">
            Submit Deck
          </Button>
        ) : (
          <Button className="new-submit-btn">
            <Loader color="white" />
          </Button>
        )}
      </form>
    </div>
  );
};

export default AddDeck;
