import { Box, Group, Loader, Title } from "@mantine/core";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading-div">
      <Box className="loading-img-box">
        <div className="loading-img-div">
          <img
            className="loading-img"
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/adhoc%2Fpokemon%2Fmew.gif?alt=media"
            alt="snorlax"
          />
        </div>
        <Group
          justify="center"
          align="flex-end"
          gap="10"
          style={{ marginTop: "10px" }}
        >
          <Title>Fetching data</Title>
          <Loader type="dots" color="white" size="25" />
        </Group>
      </Box>
    </div>
  );
};

export default Loading;
