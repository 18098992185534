import { Button, Loader, Select, Text, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import contentService from "../../../../services/content";
import { notifications } from "@mantine/notifications";
import { date } from "../../../../functions/adhoc/dateTimeFormat";

const AddTournament = ({
  closeModal,
  setModalToShow,
  tournamentsList,
  setTournamentsList,
}) => {
  const typeSelect = [
    { value: "Gym Even", label: "Gym Event" },
    { value: "Town League", label: "Town League" },
    { value: "Regional League", label: "Regional League" },
    { value: "Championship", label: "Championship" },
    { value: "Others", label: "Others" },
  ];

  const [createTournament, setCreateTournament] = useState({
    type: "",
    location: "",
    link: "",
    total_participants: 0,
    date: 0,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  //handle add tournament submit
  const tournamentCreate = (e) => {
    e.preventDefault();

    const tournamentInfo = {
      ...createTournament,
      date: Timestamp.fromDate(createTournament.date).seconds,
    };

    setSubmitLoading(true);

    try {
      contentService
        .create({
          route: "tournaments",
          newObject: tournamentInfo,
        })
        .then((res) => {
          console.log(res);
          setSubmitLoading(false);
          const tournamentToAdd = {
            value: res.uid,
            label: `${tournamentInfo.type} @ ${tournamentInfo.location} (${date(
              tournamentInfo.date
            )})`,
          };

          setTournamentsList([...tournamentsList, tournamentToAdd]);

          notifications.show({
            title: "Success",
            message: `${tournamentInfo.type} @ ${
              tournamentInfo.location
            } (${date(tournamentInfo.date)}) has been added`,
            color: "teal",
          });
        })
        .then(() => {
          setCreateTournament({
            type: "",
            location: "",
            link: "",
            total_participants: 0,
            date: 0,
          });
          closeModal();
          setModalToShow("");
        });
    } catch (err) {
      setSubmitLoading(false);
      console.error(err.message);
      notifications.show({
        title: "Error",
        message: err.message,
        color: "red",
      });
    }
  };

  return (
    <div className="new-div">
      <form onSubmit={tournamentCreate} className="new-form">
        <Select
          data={typeSelect}
          label="Type"
          value={createTournament.type}
          onChange={(e) =>
            setCreateTournament({
              ...createTournament,
              type: e,
            })
          }
          className="new-input long"
          required
        />
        <TextInput
          label="Location"
          value={createTournament.location}
          onChange={(e) =>
            setCreateTournament({
              ...createTournament,
              location: e.target.value,
            })
          }
          className="new-input long"
          required
        />
        <TextInput
          label="Total Participants"
          value={createTournament.total_participants}
          onChange={(e) =>
            setCreateTournament({
              ...createTournament,
              total_participants: e.target.value,
            })
          }
          className="new-input long"
          required
          type="number"
        />
        <TextInput
          label="Link"
          value={createTournament.link}
          onChange={(e) =>
            setCreateTournament({
              ...createTournament,
              link: e.target.value,
            })
          }
          className="new-input long"
        />
        <div className="new-date-div">
          <Text>Date</Text>
          <DatePicker
            locale="my"
            className="new-date"
            value={createTournament.date}
            onChange={(e) =>
              setCreateTournament({
                ...createTournament,
                date: e,
              })
            }
            required
          />
        </div>
        {!submitLoading ? (
          <Button type="submit" className="new-submit-btn">
            Submit Tournament
          </Button>
        ) : (
          <Button className="new-submit-btn">
            <Loader color="white" />
          </Button>
        )}
      </form>
    </div>
  );
};

export default AddTournament;
