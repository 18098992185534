import { Button, Group, Loader, Select, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import spriteSelectData from "../../../../lists/pokemon/spriteSelectData";
import {
  IconCircleMinus,
  IconCirclePlus,
  IconTrash,
} from "@tabler/icons-react";
import contentService from "../../../../services/content";
import { notifications } from "@mantine/notifications";

const AddTrainerDecks = ({ trainersList, tournamentId }) => {
  const [trainerField, setTrainerField] = useState([
    [{ trainerId: "", recorded: false }],
  ]);
  const [deckField, setDeckField] = useState([
    { sprite1: "", sprite2: "", sprite3: "", deckId: "" },
  ]);
  const [deckSearchRes, setDeckSearchRes] = useState([""]);
  const [decksList, setDecksList] = useState([[]]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [recordedTrainerIds, setRecordedTrainerIds] = useState([]);
  const [loadingRecorded, setLoadingRecorded] = useState(false);

  //get list of recorded trainers
  useEffect(() => {
    setLoadingRecorded(true);
    if (tournamentId) {
      contentService
        .get({ route: `tournaments/${tournamentId}/all-trainer-deck` })
        .then((res) => {
          setLoadingRecorded(false);
          let data = [];
          res.forEach((trainer) => data.push(trainer.trainer_id));
          setRecordedTrainerIds(data);
        })
        .catch((err) => {
          setLoadingRecorded(false);
          console.error(err);
        });
    }
  }, [tournamentId]);

  //change handler for trainer field
  const handleTrainerFieldSelect = (deckI, trainerI, e) => {
    let data = [...trainerField];
    data[deckI][trainerI].trainerId = e;

    if (recordedTrainerIds.includes(e)) {
      data[deckI][trainerI].recorded = true;
    } else if (!recordedTrainerIds.includes(e)) {
      data[deckI][trainerI].recorded = false;
    }

    setTrainerField(data);
  };

  //change handler for deck field
  const handleDeckFieldSelect = (i, e, change) => {
    let data = [...deckField];
    data[i][change] = e;
    setDeckField(data);
  };

  //handler to add a new deck field
  const addNewDeckField = () => {
    const newDeck = { sprite1: "", sprite2: "", sprite3: "", deckId: "" };
    const newTrainer = [{ trainerId: "", recorded: false }];
    const newDeckSearchRes = "";
    const newDeckList = [];
    setDeckField([...deckField, newDeck]);
    setTrainerField([...trainerField, newTrainer]);
    setDeckSearchRes([...deckSearchRes, newDeckSearchRes]);
    setDecksList([...decksList, newDeckList]);
  };

  //handler to add a new trainer field
  const addNewTrainerField = (deckI) => {
    const newTrainer = { trainerId: "" };
    let data = [...trainerField];
    let deckIndexToAdd = [...trainerField[deckI]];

    data[deckI] = [...deckIndexToAdd, newTrainer];

    setTrainerField(data);
  };

  //handler to del deck field
  const delDeckField = (i) => {
    const deckData = [...deckField];
    const trainerData = [...trainerField];
    const searchResData = [...deckSearchRes];
    const listData = [...decksList];

    deckData.splice(i, 1);
    trainerData.splice(i, 1);
    searchResData.splice(i, 1);
    listData.splice(i, 1);

    setDeckField(deckData);
    setTrainerField(trainerData);
    setDeckSearchRes(searchResData);
    setDecksList(listData);
  };

  //handler to del trainer field
  const delTrainerField = (deckI, trainerI) => {
    let data = [...trainerField];
    let deckIndexToDel = [...trainerField[deckI]];
    data[deckI] = deckIndexToDel.filter(
      (deck) => deck !== deckIndexToDel[trainerI]
    );
    setTrainerField(data);
  };

  //search handler
  const searchDecks = (i) => {
    const sprites = {
      sprite1: deckField[i].sprite1,
      sprite2: deckField[i].sprite2,
      sprite3: deckField[i].sprite3,
    };

    let searchRes = [...deckSearchRes];

    searchRes.splice(i, 1, "loading");

    setDeckSearchRes(searchRes);

    contentService
      .get({ route: "decks/sprite", params: sprites, timeout: 10000 })
      .then((res) => {
        if (res.length === 0) {
          searchRes.splice(i, 1, "none");
          setDeckSearchRes(searchRes);
        } else if (res.length > 0) {
          searchRes.splice(i, 1, "found");
          setDeckSearchRes(searchRes);
        }

        const list = [...decksList];
        let listArray = [];
        res.forEach((deck) => {
          listArray.push({
            value: deck.id,
            label: deck.deck_name,
          });
        });
        list.splice(i, 1, listArray);
        setDecksList(list);
      });
  };

  //handle deck search res
  const deckSearchToShow = (i, deck) => {
    switch (deckSearchRes[i]) {
      case "":
        return null;
      case "none":
        return "No decks found";
      case "found":
        return (
          <Select
            className="new-input"
            label="Deck"
            required
            data={decksList[i]}
            value={deck.deckId}
            onChange={(e) => {
              handleDeckFieldSelect(i, e, "deckId");
            }}
          />
        );
      case "loading":
        return <Loader />;
      default:
        return null;
    }
  };

  //submit handler
  const submitTrainerDecks = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    let data = [];

    trainerField.forEach((trainers, fieldI) => {
      trainers.forEach((trainer) => {
        data.push({
          trainer_id: trainer.trainerId,
          deck_id: deckField[fieldI].deckId,
        });
      });
    });

    const check = () => {
      if (
        data.find(
          (deck) => deck.trainer_id.length === 0 || deck.deck_id.length === 0
        )
      ) {
        return false;
      } else return true;
    };

    if (check()) {
      try {
        await contentService.create({
          route: `tournaments/${tournamentId}/trainer-deck`,
          newObject: data,
        });

        setSubmitLoading(false);
        setTrainerField([]);
        setDeckField([]);
        setDeckSearchRes([]);
        setDecksList([]);
        notifications.show({
          title: "Success",
          color: "green",
          message: `${data?.length} trainer decks has been added to tournament ID: ${tournamentId}`,
        });
      } catch (error) {
        setSubmitLoading(false);
        console.error(error);
        notifications.show({
          title: "Error",
          color: "red",
          message: error,
        });
      }
    } else if (!check()) {
      setSubmitLoading(false);
      notifications.show({
        title: "Error",
        color: "red",
        message: "One or more fields are missing",
      });
    }
  };

  if (loadingRecorded) {
    return (
      <Title style={{ textAlign: "center", marginTop: "20px" }}>
        Loading list of trainer decks that have been recorded...
      </Title>
    );
  }

  return (
    <form className="new-form" onSubmit={submitTrainerDecks}>
      {deckField?.map((deck, deckI) => (
        <div key={deckI} className="new-dynamic-field-div">
          <IconTrash
            color="red"
            className="new-dynamic-field-del"
            onClick={() => delDeckField(deckI)}
          />
          <Text fw={700}>Deck {deckI + 1}</Text>
          <Group className="new-dynamic-field-grp">
            <Select
              label="Sprite 1"
              required
              searchable
              data={spriteSelectData}
              className="new-input"
              value={deck.sprite1}
              onChange={(e) => handleDeckFieldSelect(deckI, e, "sprite1")}
            />
            <Select
              label="Sprite 2"
              searchable
              data={spriteSelectData}
              className="new-input"
              value={deck.sprite2}
              onChange={(e) => handleDeckFieldSelect(deckI, e, "sprite2")}
            />
            <Select
              label="Sprite 3"
              searchable
              data={spriteSelectData}
              className="new-input"
              value={deck.sprite3}
              onChange={(e) => handleDeckFieldSelect(deckI, e, "sprite3")}
            />
            <Button onClick={() => searchDecks(deckI)}>Search</Button>
            {deckSearchToShow(deckI, deck)}
          </Group>
          <Text td="underline" style={{ marginTop: "20px" }}>
            Trainers (Total: {trainerField[deckI].length})
          </Text>
          <IconCirclePlus
            color="green"
            className="new-dynamic-field-add-del"
            onClick={() => addNewTrainerField(deckI)}
          />
          <Group>
            {trainerField[deckI].map((trainer, trainerI) => (
              <Group
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={trainerI}
              >
                <Select
                  className={`new-input ${trainer.recorded ? "error" : ""}`}
                  data={trainersList}
                  value={trainer.trainerId}
                  onChange={(e) => {
                    handleTrainerFieldSelect(deckI, trainerI, e);
                  }}
                  searchable
                  required
                />
                <IconCircleMinus
                  color="red"
                  className="new-dynamic-field-add-del no-margin"
                  onClick={() => delTrainerField(deckI, trainerI)}
                />
              </Group>
            ))}
          </Group>
        </div>
      ))}
      <IconCirclePlus
        color="green"
        className="new-dynamic-field-add-del"
        onClick={addNewDeckField}
      />
      {!submitLoading ? (
        <Button className="new-submit-btn" type="submit">
          Submit
        </Button>
      ) : (
        <Button className="new-submit-btn" disabled>
          <Loader color="white" size="18" />
        </Button>
      )}
    </form>
  );
};

export default AddTrainerDecks;
