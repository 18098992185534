// import { NativeAds } from "../../components/ads/Adsterra/Ads";
// import { NativeAds } from "../../components/ads/Adsterra/Ads";
import HomeCards from "../../components/home/HomeCards";
import HomeTop from "../../components/home/HomeTop";
import HomeTopMobile from "../../components/home/HomeTopMobile";
import LatestHome from "../../components/home/LatestHome";
import useScreenSize from "../../hooks/useScreenSize";

import "./Home.css";

const Home = () => {
  const screenSize = useScreenSize();
  return (
    <div className="page-div">
      {/* <CarouselHome /> */}
      {/* <NativeAds /> */}
      {screenSize.width < 900 ? <HomeTopMobile /> : <HomeTop />}
      {/* <HomeCards /> */}
      <LatestHome />
      {/* <NativeAds /> */}
    </div>
  );
};

export default Home;
