import axios from "axios";
import { token } from "./token";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const get = async ({ route = "", params = {}, timeout = 0 }) => {
  const config = {
    params,
    timeout,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  try {
    const res = await axios.get(`${baseUrl}/${route}`, config);
    return res.data;
  } catch (err) {
    console.error(err.message);
  }
};

const create = async ({
  route = "",
  newObject = {},
  params = {},
  contentType = "application/json",
}) => {
  const config = {
    params,
    headers: {
      "Content-Type": contentType,
      Authorization: token,
    },
  };

  try {
    const res = await axios.post(`${baseUrl}/${route}`, newObject, config);
    return res.data;
  } catch (err) {
    console.error(err.message);
  }
};

const update = async ({ route = "", updatedObject = {}, params = {} }) => {
  const config = {
    params,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  try {
    const res = await axios.put(`${baseUrl}/${route}`, updatedObject, config);
    return res.data;
  } catch (err) {
    console.error(err.message);
  }
};

const contentService = { get, create, update };

export default contentService;
