import { useParams } from "react-router-dom";
import { Text, Title } from "@mantine/core";
import StoreForm from "../../components/admin/forms/StoreForm";
import ArticleForm from "../../components/admin/forms/ArticleForm";
import TournamentForm from "../../components/admin/forms/TournamentForm";

const AddNew = ({ userDoc }) => {
  const type = useParams().type;

  const handleTitle = (type) => {
    switch (type) {
      case "article":
        return "Article";
      case "store":
        return "Store";
      case "tournament":
        return "Tournament Data";
      default:
        return "";
    }
  };

  const formToRender = () => {
    switch (type) {
      case "article":
        return <ArticleForm mode="add" />;
      case "store":
        return <StoreForm mode="add" />;
      case "tournament":
        return <TournamentForm mode="add" />;
      default:
        return "";
    }
  };

  return (
    <div className="page-div">
      {userDoc?.role !== "admin" ? (
        <Text className="warning">
          You do not have the permission to view this page
        </Text>
      ) : (
        <div>
          <Title className="page-title">{`Add a new ${handleTitle(
            type
          )}`}</Title>
          {formToRender()}
        </div>
      )}
    </div>
  );
};

export default AddNew;
