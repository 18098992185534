import { List, Modal, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import AddSprite from "../../../components/admin/forms/cards/AddSprite";
import contentService from "../../../services/content";
import NoSpriteList from "../../../components/admin/forms/cards/NoSpriteList";
import { notifications } from "@mantine/notifications";

const LinkSprite = ({ userDoc }) => {
  const [cards, setCards] = useState([]);
  const [cardId, setCardId] = useState("");
  const [card, setCard] = useState([]);
  const [sprite, setSprite] = useState("");
  const [openedModal, setOpenedModal] = useState(false);
  const [searchRes, setSearchRes] = useState("");
  const [postLoading, setPostLoading] = useState(false);

  const openModal = () => setOpenedModal(true);
  const closeModal = () => {
    setOpenedModal(false);
    setCardId("");
    setSprite("");
  };

  useEffect(() => {
    setSearchRes("loading");
    if (userDoc?.role === "admin") {
      try {
        contentService.get({ route: "cards/no-sprite" }).then((res) => {
          if (res.length === 0) {
            setSearchRes("none");
          } else {
            setSearchRes("found");
            setCards(res);
          }
        });
      } catch (err) {
        console.error(err.message);
      }
    }
  }, [userDoc]);

  //handle click event on card uid
  const handleUidClick = (card) => {
    setCardId(card.uid);
    openModal();
  };

  //handle event to link sprite to card
  const linkSprite = (e) => {
    e.preventDefault();

    setPostLoading(true);

    const object = { card_id: cardId, sprite: sprite };

    try {
      contentService
        .create({
          route: `cards/${cardId}/sprite`,
          newObject: object,
        })
        .then(() => {
          setPostLoading(false);
          const cardToFilter = cardId;
          setCards(cards.filter((card) => card.uid !== cardToFilter));
          notifications.show({
            title: "Success",
            color: "green",
            message: `${card?.name} has been linked to a sprite`,
          });
        })
        .then(() => {
          closeModal();
        });
    } catch (err) {
      console.error(err.message);
    }
  };

  if (userDoc?.role !== "admin") {
    return "";
  } else if (userDoc.role === "admin") {
    return (
      <div className="page-div">
        <Modal
          opened={openedModal}
          onClose={closeModal}
          centered
          zIndex="300"
          className="new-modal"
        >
          <AddSprite
            uid={cardId}
            sprite={sprite}
            setSprite={setSprite}
            linkSprite={linkSprite}
            card={card}
            setCard={setCard}
            postLoading={postLoading}
          />
        </Modal>
        <Title className="page-title">Link Sprite to Card</Title>
        <List>
          <NoSpriteList
            cards={cards}
            handleUidClick={handleUidClick}
            searchRes={searchRes}
          />
        </List>
      </div>
    );
  }
};

export default LinkSprite;
