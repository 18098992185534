import { useState } from "react";
import { resetPassword } from "../../firebase";
import { Input, Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAt, IconRefresh } from "@tabler/icons-react";

import "./UserAuth.css";

const Reset = ({ setComp }) => {
  const [email, setEmail] = useState("");

  //reset handler
  const handleReset = () => {
    if (!email) {
      notifications.show({
        title: "Error",
        message: "Please enter a valid e-mail",
        color: "red",
      });
    } else {
      resetPassword(email);
    }
  };

  //check to disable register button if credentials are not found
  const resetBtnDisabled = () => {
    if (!email) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="user-auth-body">
      <IconRefresh color="#f05454" size="50px" />
      <h2 className="user-auth-title">Register</h2>
      <form onSubmit={handleReset}>
        <Input
          placeholder="E-mail Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
          className="user-auth-input"
          leftSection={<IconAt />}
        />
        <Button
          type="submit"
          className="user-auth-email-btn"
          disabled={resetBtnDisabled()}
          fullWidth
        >
          Reset Password
        </Button>
      </form>
      <p className="user-auth-switch" onClick={() => setComp("login")}>
        Back to login
      </p>
    </div>
  );
};

export default Reset;
