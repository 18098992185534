const locale = { timezone: "UTC+8" };

export const commentDateTime = (unix) => {
  const dateTime = new Date(unix * 1000);
  const format = dateTime.toLocaleString("en-US", locale);

  return format;
};

export const dayDate = (unix) => {
  const dateTime = new Date(unix * 1000);
  const option = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const format = dateTime.toLocaleDateString("en-US", option);
  return format;
};

export const date = (unix) => {
  const dateTime = new Date(unix * 1000);
  const option = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    timezone: "UTC+8",
  };

  const format = dateTime.toLocaleDateString("en-US", option);
  return format;
};

export const isoDate = (unix) => {
  const dateTime = new Date(unix * 1000);
  return dateTime.toISOString();
};
