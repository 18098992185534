import { Button, Loader, Select, Title } from "@mantine/core";
import spriteSelectData from "../../../../lists/pokemon/spriteSelectData";

import "../AdminForm.css";
import contentService from "../../../../services/content";
import { useEffect } from "react";

const spritePngUrl =
  "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Sprites%2FPokemon%2Fpng%2F";

const AddSprite = ({
  uid,
  sprite,
  setSprite,
  linkSprite,
  card,
  setCard,
  postLoading,
}) => {
  useEffect(() => {
    const params = {
      card_type: "pokemon",
      language: "en",
    };

    if (uid) {
      try {
        contentService
          .get({ route: `cards/${uid}`, params: params })
          .then((res) => setCard(res));
      } catch (err) {
        console.error(err.message);
      }
    } else if (!uid) {
      setCard([]);
    }
  }, [uid, setCard]);

  const showSprite = () => {
    if (!sprite) {
      return null;
    } else if (sprite) {
      return (
        <img
          src={`${spritePngUrl}${sprite}.png?alt=media`}
          alt="sprite"
          style={{ widht: "70px" }}
        />
      );
    }
  };

  return (
    <div className="new-div center">
      <Title>{card?.name}</Title>
      <img className="new-info-img" src={card?.images} alt="card" />
      {showSprite()}
      <form onSubmit={linkSprite}>
        <Select
          className="new-input"
          data={spriteSelectData}
          value={sprite}
          onChange={setSprite}
          label="Sprite"
          required
          searchable
        />

        {!postLoading ? (
          <Button type="submit" className="new-submit-btn">
            Link
          </Button>
        ) : (
          <Button className="new-submit-btn">
            <Loader color="white" />
          </Button>
        )}
      </form>
    </div>
  );
};

export default AddSprite;
