import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import "react-markdown-editor-lite/lib/index.css";
import { useEffect, useState } from "react";
import {
  Button,
  FileInput,
  Group,
  Loader,
  Text,
  TextInput,
} from "@mantine/core";
import { IconCircleMinus, IconCirclePlus, IconCopy } from "@tabler/icons-react";

import "./AdminForm.css";
import { Timestamp } from "firebase/firestore";
import contentService from "../../../services/content";
import { notifications } from "@mantine/notifications";
import { useParams } from "react-router-dom";

const ArticleForm = ({ mode }) => {
  const id = useParams().id;

  const [input, setInput] = useState({
    title: "",
    author: "",
    imgURL: "",
    subtitle: "",
    likes: [],
    comments: [],
  });
  const [tagFields, setTagFields] = useState([""]);
  const [body, setBody] = useState("");
  const [imgUpload, setImgUpload] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState([]);

  const timeNow = Timestamp.fromDate(new Date()).seconds;

  console.log(body);

  useEffect(() => {
    if (mode === "edit") {
      contentService.get({ route: `articles/${id}` }).then((res) => {
        setInput({
          title: res.title,
          author: res.author,
          imgURL: res.imgURL,
          subtitle: res.subtitle,
          likes: res.likes,
          comments: res.comments,
          createdAt: res.createdAt,
          updatedAt: res.updatedAt,
        });
        setTagFields(res.tags);
        setBody({ text: res.body });
      });
    }
  }, [mode, id]);

  //change handler for input fields
  const handleInputChange = (e, type) => {
    setInput({ ...input, [type]: e.target.value });
  };

  //handle change to tag input
  const handleTagFieldsChange = (i, e) => {
    let data = [...tagFields];
    data[i] = e.target.value;
    setTagFields(data);
  };

  //handler to add more tags
  const addTagField = () => {
    let newTag = "";
    setTagFields([...tagFields, newTag]);
  };

  //handler to delete tag
  const delTagField = () => {
    let data = [...tagFields];
    data.pop();
    setTagFields(data);
  };

  //parser for markdown
  const mdParser = new MarkdownIt();

  //upload image
  const uploadImg = async (e) => {
    e.preventDefault();

    setUploadLoading(true);
    if (mode === "edit") {
      if (imgUpload) {
        try {
          const fileType = imgUpload.name.split(".").pop();
          const res = await contentService.create({
            route: "storage",
            newObject: { file: imgUpload, type: `image/${fileType}` },
            params: { prefix: `article/${id}` },
            contentType: "multipart/form-data",
          });
          setUploadLoading(false);
          setImgUpload();
          setUploadedImg([...uploadedImg, res.url]);
        } catch (err) {
          console.error(err);
          setUploadLoading(false);
        }
      }
    }
  };

  //handle add form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const articleData = {
      title: input.title,
      subtitle: input.subtitle,
      body: "",
      author: input.author,
      createdAt: mode === "add" ? timeNow : input.createdAt,
      updatedAt: timeNow,
      tags: tagFields,
      imgURL: input.imgURL,
      likes: mode === "add" ? [] : input.likes,
      comments: mode === "add" ? [] : input.comments,
    };

    const updateArticleData = {
      title: input.title,
      subtitle: input.subtitle,
      body: body?.text,
      author: input.author,
      createdAt: mode === "add" ? timeNow : input.createdAt,
      updatedAt: timeNow,
      tags: tagFields,
      imgURL: input.imgURL,
      likes: mode === "add" ? [] : input.likes,
      comments: mode === "add" ? [] : input.comments,
    };

    try {
      //submit new article function
      if (mode === "add") {
        contentService
          .create({ route: "articles", newObject: articleData })
          .then((res) => {
            notifications.show({
              title: "Success",
              message: `${input.title} has been added to articles`,
              color: "teal",
            });
            window.location.href = `/edit/article/${res.uid}`;
          });
      } else if (mode === "edit") {
        contentService
          .update({
            route: `articles/${id}`,
            updatedObject: updateArticleData,
            params: { card_type: "pokemon", language: "en" },
          })
          .then(() => {
            notifications.show({
              title: "Success",
              message: `${input.title} has been edited`,
              color: "teal",
            });
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      notifications.show({
        title: "Error",
        message: `An error has occured. ${input.title} has not been added to articles`,
        color: "red",
      });
      console.error(err.message);
    }
  };

  return (
    <div className="new-div">
      <form className="new-form" onSubmit={(e) => handleSubmit(e)}>
        <Group>
          <TextInput
            label="Title"
            value={input.title}
            onChange={(e) => handleInputChange(e, "title")}
            className="new-input long"
            name="title"
            required
          />
          <TextInput
            label="Subtitle"
            value={input.subtitle}
            onChange={(e) => handleInputChange(e, "subtitle")}
            className="new-input long"
            name="subtitle"
            required
          />
          <TextInput
            label="Image URL"
            value={input.imgURL}
            onChange={(e) => handleInputChange(e, "imgURL")}
            className="new-input long"
            name="imgURL"
            required
          />
          <TextInput
            label="Author"
            value={input.author}
            onChange={(e) => handleInputChange(e, "author")}
            className="new-input"
            name="author"
            required
          />
        </Group>
        <div className="new-dynamic-field-div ">
          <Text>Tags</Text>
          <Group>
            {tagFields.map((tag, i) => (
              <TextInput
                value={tag}
                key={i}
                onChange={(e) => handleTagFieldsChange(i, e)}
                className="new-input"
              />
            ))}
            <IconCirclePlus
              color="green"
              className="new-dynamic-field-add-del"
              onClick={addTagField}
            />
            {tagFields.length === 1 ? (
              ""
            ) : (
              <IconCircleMinus
                color="red"
                className="new-dynamic-field-add-del"
                onClick={() => delTagField()}
              />
            )}
          </Group>
        </div>
        {mode === "edit" ? (
          <div>
            <Group align="flex-end">
              {/* <TestImg /> */}
              <FileInput
                size="md"
                value={imgUpload}
                onChange={setImgUpload}
                label="Upload Image"
                description="Select a file"
                accept="image/png,image/jpeg"
                className="new-input"
              />
              {!uploadLoading ? (
                <Button onClick={uploadImg} disabled={imgUpload ? false : true}>
                  Upload
                </Button>
              ) : (
                <Button disabled>
                  <Loader color="white" size="18" />
                </Button>
              )}
            </Group>
            <Text className="new-subtitle">Uploaded Images</Text>
            <Group>
              {uploadedImg.map((img, i) => (
                <div key={i} className="form-uploaded-img-div">
                  <div className="form-uploaded-img-copy-div">
                    <IconCopy
                      onClick={() => {
                        navigator.clipboard.writeText(img);
                        notifications.show({
                          title: "Success",
                          message: `Copied "${img}"`,
                          color: "teal",
                        });
                      }}
                      style={{ cursor: "pointer" }}
                      size="70"
                      color="white"
                    />
                  </div>
                  <img src={img} alt="uploaded" className="form-uploaded-img" />
                </div>
              ))}
            </Group>
          </div>
        ) : null}
        {mode === "edit" ? (
          <div className="new-markdown-div">
            <MdEditor
              renderHTML={(body) => mdParser.render(body)}
              onChange={setBody}
              value={body.text}
              placeholder="Body"
              style={{ height: "500px" }}
              required
            />
          </div>
        ) : null}
        <Button type="submit" className="new-submit-btn" radius="xl">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ArticleForm;
