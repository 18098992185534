import { useParams } from "react-router-dom";
import "./SearchResult.css";
import { useEffect, useRef, useState } from "react";
import contentService from "../../services/content";
import { Group, Loader, Tabs, Text, Title } from "@mantine/core";
import CardCard from "../../components/cards/CardCard";
import ArticlesCard from "../../components/articles/ArticlesCard";
import { IconCards, IconNews } from "@tabler/icons-react";

const GenericResult = () => {
  const term = useParams().term;
  const type = useParams().type;

  const [cardsResult, setCardsResult] = useState([]);
  const [articlesResult, setArticlesResult] = useState([]);
  const [resultPage, setResultPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const lastItemRef = useRef(null);

  //fetch search result
  useEffect(() => {
    const fetchResult = async () => {
      try {
        const params = {
          query: term,
          card_type: "pokemon",
          language: "en",
          page: resultPage,
        };

        await contentService
          .get({ route: "search", params: params })
          .then((res) => {
            if (res.cards.length > 0 || res.articles.length > 0) {
              setCardsResult([...cardsResult, ...res?.cards]);
              setArticlesResult([...articlesResult, ...res?.articles]);
              setLastLoadedPage(lastLoadedPage + 1);
              setIsLoaded(true);
            } else {
              setFullyLoaded(true);
            }
          });
      } catch (error) {
        console.error(error);
      }
    };

    if (lastLoadedPage < resultPage) {
      fetchResult();
    }
  }, [
    resultPage,
    cardsResult,
    articlesResult,
    lastLoadedPage,
    setIsLoaded,
    term,
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (isLoaded && entry.isIntersecting && !fullyLoaded) {
          setTimeout(() => {
            setResultPage((resultPage) => resultPage + 1);
            setIsLoaded(false);
          }, 1000);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.7,
      }
    );
    // set target ref
    const currentTarget = lastItemRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [isLoaded, lastItemRef, setResultPage, setIsLoaded, fullyLoaded]);

  //render cards
  let allCards = [];
  cardsResult?.forEach((card, i) => {
    let cardCard = <CardCard card={card} key={i} />;
    allCards.push(cardCard);
  });

  //render articles
  let allArticles = [];
  articlesResult?.forEach((article, i) => {
    let articleCard = <ArticlesCard article={article} key={i} />;
    allArticles.push(articleCard);
  });

  const [cardRender, setCardRender] = useState(true);
  const [articleRender, setArticleRender] = useState(true);

  setTimeout(() => {
    if (cardsResult.length === 0) {
      setCardRender(false);
    } else {
      setCardRender(true);
    }
  }, 3000);

  setTimeout(() => {
    if (articlesResult.length === 0) {
      setArticleRender(false);
    } else {
      setArticleRender(true);
    }
  }, 3000);

  const resultCheck = (renderer) => {
    if (!renderer) {
      return <Text className="search-result-not-found">No result found</Text>;
    } else if (renderer) {
      if (!fullyLoaded) {
        return <Loader color="dark" variant="dots" size="xl" />;
      } else {
        return "";
      }
    }
  };

  //set default tab
  const defaultTab = () => {
    switch (type) {
      case "all":
        return "cards";
      case "cards":
        return "cards";
      case "articles":
        return "articles";
      case "tournaments":
        return "tournaments";
      default:
        return "cards";
    }
  };

  return (
    <div className="page-div">
      <Title className="page-title">Search Result for "{term}"</Title>
      <Tabs
        defaultValue={defaultTab()}
        color="dark"
        variant="default"
        unstyled
        styles={() => ({
          tab: {
            "&[data-active]": {
              backgroundColor: "#30475e",
              color: "#ffffff",
            },
          },
        })}
        className="result-tabs"
      >
        <Tabs.List className="search-result-tabs-list">
          <Group justify="center">
            <Tabs.Tab
              value="cards"
              className="search-result-tab"
              leftSection={<IconCards className="search-result-tab-icon" />}
            >
              Cards
            </Tabs.Tab>
            <Tabs.Tab
              value="articles"
              className="search-result-tab"
              leftSection={<IconNews className="search-result-tab-icon" />}
            >
              Articles
            </Tabs.Tab>
          </Group>
        </Tabs.List>
        <div className="search-result-panels-div">
          <Tabs.Panel value="cards">
            <Group justify="center">{allCards}</Group>
            <Group justify="center" className="search-result-loader-grp">
              {resultCheck(cardRender)}
            </Group>
          </Tabs.Panel>
          <Tabs.Panel value="articles">
            <Group justify="center">{allArticles}</Group>
            <Group justify="center" className="search-result-loader-grp">
              {resultCheck(articleRender)}
            </Group>
          </Tabs.Panel>
        </div>
      </Tabs>
      <div ref={lastItemRef} className="page-loader"></div>
    </div>
  );
};

export default GenericResult;
