import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  IconSignature,
  IconTypography,
  IconPhone,
  IconMapPinFilled,
  IconBuilding,
  IconLocation,
  IconMap2,
  IconUser,
} from "@tabler/icons-react";
import { Button, Group, Input, Select, Title } from "@mantine/core";

import userService from "../../services/user";
import { userStateList } from "../../lists/states/userStateList";
import "./AddInfo.css";
import genderData from "../../lists/adhoc/gender";

const AddInfo = ({ user }) => {
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    name: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    address2: "",
    city: "",
    postcode: "",
  });
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");

  //navigate to home if user has additional info
  useEffect(() => {
    if (user) {
      userService.checkAdditionalInfo(user).then((res) => {
        if (res) {
          navigate("../");
        }
      });
    }
  }, [user, navigate]);

  const handleChange = (e, value) => {
    setInfo({ ...info, [value]: e.target.value });
  };

  //update user docs handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userDoc = {
        name: info.name,
        firstName: info.firstName,
        lastName: info.lastName,
        gender,
      };

      const userPrivateDoc = {
        phoneNumber: info.phoneNumber,
        address: info.address,
        address2: info.address2,
        city: info.city,
        state,
        postcode: info.postcode,
      };

      await userService.updateUserDoc(user, userDoc);
      await userService.updateUserPrivateDoc(user, userPrivateDoc);
      window.location.reload();
      return navigate("../");
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="page-div">
      <Title className="page-title">Additional Info</Title>
      <form onSubmit={(e) => handleSubmit(e)} id="add-info-form">
        <Input
          className="add-info-input"
          value={info.name}
          placeholder="Display Name"
          onChange={(e) => handleChange(e, "name")}
          icon={<IconSignature />}
          radius="lg"
          size="md"
          required
        />
        <Input
          className="add-info-input"
          value={info.firstName}
          placeholder="First Name"
          onChange={(e) => handleChange(e, "firstName")}
          icon={<IconTypography />}
          radius="lg"
          size="md"
          required
        />
        <Input
          className="add-info-input"
          value={info.lastName}
          placeholder="Last Name"
          onChange={(e) => handleChange(e, "lastName")}
          icon={<IconTypography />}
          radius="lg"
          size="md"
          required
        />
        <Select
          value={gender}
          onChange={setGender}
          data={genderData}
          placeholder="Gender"
          radius="lg"
          size="md"
          icon={<IconUser />}
          required
          className="add-info-input"
        />
        <Input
          className="add-info-input"
          value={info.phoneNumber}
          placeholder="Phone Number"
          onChange={(e) => handleChange(e, "phoneNumber")}
          icon={<IconPhone />}
          radius="lg"
          size="md"
          required
        />
        <Input
          className="add-info-input"
          value={info.address}
          placeholder="Address Line 1"
          onChange={(e) => handleChange(e, "address")}
          icon={<IconMapPinFilled />}
          radius="lg"
          size="md"
          required
        />
        <Input
          className="add-info-input"
          value={info.address2}
          placeholder="Address Line 2"
          onChange={(e) => handleChange(e, "address2")}
          icon={<IconMapPinFilled />}
          radius="lg"
          size="md"
          required
        />
        <Input
          className="add-info-input"
          value={info.city}
          placeholder="City"
          onChange={(e) => handleChange(e, "city")}
          icon={<IconBuilding />}
          radius="lg"
          size="md"
          required
        />
        <Group className="add-info-input">
          <Input
            className="add-info-input-small"
            value={info.postcode}
            placeholder="Postcode"
            onChange={(e) => handleChange(e, "postcode")}
            icon={<IconLocation />}
            radius="lg"
            size="md"
            required
          />
          <Select
            value={state}
            onChange={setState}
            data={userStateList}
            placeholder="State"
            radius="lg"
            size="md"
            icon={<IconMap2 />}
            searchable
            required
            className="add-info-input-small"
          />
        </Group>
        <Button
          type="submit"
          className="add-info-submit-btn"
          radius="xl"
          fullWidth
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddInfo;
