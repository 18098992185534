import { DiscussionEmbed } from "disqus-react";
import { useParams } from "react-router-dom";
import "./Disqus.css";

const Disqus = ({ title }) => {
  const id = useParams().id;

  const url = window.location.href;

  const disqusConfig = {
    url: url,
    identifier: id,
    title: title,
  };

  return (
    <DiscussionEmbed
      shortname="timmy-cards"
      config={disqusConfig}
      className="disqus-embed"
    />
  );
};

export default Disqus;
