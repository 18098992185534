import pkm from "./pkm.png";
import dgm from "./dgm.png";
import fortyk from "./40k.png";
import bd from "./bd.png";
import board from "./board.png";
import bs from "./bs.png";
import dnd from "./d&d.png";
import dm from "./dm.png";
import fnb from "./f&b.png";
import mtg from "./mtg.png";
import op from "./op.png";
import sv from "./sv.png";
import vg from "./vg.png";
import ws from "./ws.png";
import ygo from "./ygo.png";
import ga from "./ga.png";

const allGamesIcons = {
  pkm,
  dgm,
  fortyk,
  bd,
  board,
  bs,
  dnd,
  dm,
  fnb,
  mtg,
  op,
  sv,
  vg,
  ws,
  ygo,
  ga,
};

export default allGamesIcons;
