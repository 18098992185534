import allGamesIcons from "../../lists/games/icons/allGamesIcons";

const gamesIcon = (game) => {
  switch (game) {
    case "PKM":
      return allGamesIcons.pkm;
    case "DGM":
      return allGamesIcons.dgm;
    case "YGO":
      return allGamesIcons.ygo;
    case "OP":
      return allGamesIcons.op;
    case "MTG":
      return allGamesIcons.mtg;
    case "F&B":
      return allGamesIcons.fnb;
    case "VG":
      return allGamesIcons.vg;
    case "WS":
      return allGamesIcons.ws;
    case "DM":
      return allGamesIcons.dm;
    case "BD":
      return allGamesIcons.bd;
    case "BS":
      return allGamesIcons.bs;
    case "SV":
      return allGamesIcons.sv;
    case "D&D":
      return allGamesIcons.dnd;
    case "40K":
      return allGamesIcons.fortyk;
    case "GA":
      return allGamesIcons.ga;
    case "Board":
      return allGamesIcons.board;
    default:
      return "";
  }
};

export default gamesIcon;
