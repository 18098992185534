import { Group, Select, Text, Title, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import "./DecksDistribution.css";
import { IconArrowsSort } from "@tabler/icons-react";
import XAxisSprites from "./XAxisSprites";
import { isMobile } from "react-device-detect";

const DecksDistribution = ({
  tournament,
  dataFound,
  chartData,
  knownDeckCount,
  tableData,
}) => {
  const [sortedChartData, setSortedChartData] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [sort, setSort] = useState("high");

  const spritePngUrl =
    "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Sprites%2FPokemon%2Fpng%2F";

  // useEffect(() => {
  //   let data = [];
  //   // decks
  //   //   .sort((a, b) => (a.deckCount > b.deckCount ? -1 : 1))
  //   decks.forEach((deck) => {
  //     data.push({
  //       deck: deck.deck,
  //       card1: deck.card1,
  //       card2: deck.card2,
  //       card1Id: deck.card1Id,
  //       card2Id: deck.card2Id,
  //       card1Sprite: deck.card1Sprite,
  //       card2Sprite: deck.card2Sprite,
  //       deckCount: deck.deckCount,
  //     });
  //   });

  //   // setchartData(data);
  // }, [decks]);

  useEffect(() => {
    let data = [...chartData];
    let tData = [...tableData];

    if (sort === "high") {
      setSortedChartData(
        data
          .sort((a, b) => (a.deck > b.deck ? 1 : -1))
          .sort((a, b) => (a.deckCount > b.deckCount ? -1 : 1))
      );
      setSortedTableData(
        tData
          .sort((a, b) => (a.deck > b.deck ? 1 : -1))
          .sort((a, b) => (a.deckCount > b.deckCount ? -1 : 1))
      );
    } else if (sort === "low") {
      setSortedChartData(
        data
          .sort((a, b) => (a.deck > b.deck ? -1 : 1))
          .sort((a, b) => (a.deckCount > b.deckCount ? 1 : -1))
      );
      setSortedTableData(
        tData
          .sort((a, b) => (a.deck > b.deck ? -1 : 1))
          .sort((a, b) => (a.deckCount > b.deckCount ? 1 : -1))
      );
    } else if (sort === "a") {
      setSortedChartData(data.sort((a, b) => (a.deck > b.deck ? 1 : -1)));
      setSortedTableData(tData.sort((a, b) => (a.deck > b.deck ? 1 : -1)));
    } else if (sort === "z") {
      setSortedChartData(data.sort((a, b) => (a.deck > b.deck ? -1 : 1)));
      setSortedTableData(tData.sort((a, b) => (a.deck > b.deck ? -1 : 1)));
    }
  }, [sort, chartData, tableData]);

  // //change how x axis of chart is rendered
  // const renderXAxis = ({ x, y, payload }) => {
  //   const findDeck = chartData.filter((data) => data.deck === payload.value);

  //   const card1Id = findDeck[0].card1Id;

  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       <text
  //         x={0}
  //         y={10}
  //         dy={16}
  //         textAnchor="end"
  //         fill="#666"
  //         transform="rotate(-35)"
  //         className="distribution-tick"
  //         onClick={() => navigate(`/card/${card1Id}`)}
  //       >
  //         {payload.value}
  //       </text>
  //     </g>
  //   );
  // };

  //add label to bar
  const renderBarLabel = ({ payload, x, y, width, height, value }) => {
    const radius = 10;

    return (
      // <text
      //   x={x + width / 2}
      //   y={y + 30}
      //   fill="#ffffff"
      //   textAnchor="middle"
      //   dy={-6}
      //   className="distribution-chart-bar-label"
      // >
      //   {value}
      // </text>
      <g>
        <circle
          cx={x + width / 2}
          cy={y + height / 2}
          r={radius}
          fill="#ffffff"
        />
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
          className="distribution-chart-bar-label"
        >
          {value}
        </text>
      </g>
    );
  };

  //change how tooltip of chart is rendered
  const renderTooltip = ({ payload, active }) => {
    if (active) {
      const data = payload?.length > 0 ? payload[0]?.payload : null;

      return (
        <div className="distribution-bar-tooltip">
          <Text className="distribution-bar-tooltip-text deck">
            {data?.deck}
          </Text>
          <Text>
            Deck Count: {data?.deckCount} (
            {((data?.deckCount / tournament?.total_participants) * 100).toFixed(
              2
            )}
            %)
          </Text>
        </div>
      );
    }
  };

  //render chart
  const chartRender = () => {
    const barColor = [
      "#006466",
      "#065a60",
      "#0b525b",
      "#144552",
      "#1b3a4b",
      "#212f45",
      "#272640",
      "#312244",
      "#3e1f47",
      "#4d194d",
      "#3e1f47",
      "#312244",
      "#272640",
      "#212f45",
      "#1b3a4b",
      "#144552",
      "#0b525b",
      "#065a60",
      "#006466",
    ];

    return (
      <div className="distribution-tabs-panel">
        <ResponsiveContainer
          className="distribution-cont"
          width="100%"
          height="100%"
        >
          <BarChart
            data={sortedChartData}
            margin={{
              top: 5,
              bottom: 0,
            }}
            className="distribution-bar-chart"
            width={1000}
            height={500}
            // maxBarSize={70}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="deck" tick={null} interval={0} />
            <YAxis width={30} tickCount={4} />
            <ChartTooltip content={renderTooltip} />
            <Bar
              dataKey="deckCount"
              fill="#f05454d4"
              label={renderBarLabel}
              isAnimationActive
            >
              {chartData.map((entry, i) => {
                return <Cell fill={barColor[i]} key={i} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  //render spirit of card and card2
  const renderSprit = (deck) => {
    return (
      <Group justify="center" className="distribution-sprite-grp">
        <a className="basic-anchor" href={`/card/${deck.card1Id}`}>
          <img
            className="distribution-sprite table"
            src={`${spritePngUrl}${deck?.card1Sprite}.png?alt=media`}
            alt="sprite"
          />
        </a>
        {deck.card2Sprite ? (
          <a className="basic-anchor" href={`/card/${deck.card2Id}`}>
            <img
              className="distribution-sprite table"
              src={`${spritePngUrl}${deck?.card2Sprite}.png?alt=media`}
              alt="sprite"
            />
          </a>
        ) : null}
        {deck.card3Sprite ? (
          <a className="basic-anchor" href={`/card/${deck.card3Id}`}>
            <img
              className="distribution-sprite table"
              src={`${spritePngUrl}${deck?.card3Sprite}.png?alt=media`}
              alt="sprite"
            />
          </a>
        ) : null}
      </Group>
    );
  };

  // //render table
  const tableRender = () => {
    //body of table
    const tBody = () => {
      return sortedTableData?.map((data, i) => {
        return (
          <Table.Tr key={i}>
            <Table.Td className="distribution-t-body">{data.deck}</Table.Td>
            <Table.Td className="distribution-t-body sprite">
              {renderSprit(data)}
            </Table.Td>

            <Table.Td className="distribution-t-body">
              {data.deckCount}
            </Table.Td>
            <Table.Td className="distribution-t-body">
              {(
                (data.deckCount / tournament?.total_participants) *
                100
              ).toFixed(1)}
              %
            </Table.Td>
          </Table.Tr>
        );
      });
    };
    return (
      <Table
        className="distribution-t"
        highlightOnHover
        withColumnBorders
        withTableBorder
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Td className="distribution-t-head one">Deck</Table.Td>
            <Table.Td className="distribution-t-head two">Pokemon(s)</Table.Td>
            <Table.Td className="distribution-t-head three">
              Deck Count
            </Table.Td>
            <Table.Td className="distribution-t-head three">%</Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {tBody()}
          <Table.Tr>
            <Table.Td className="distribution-t-head one">Total</Table.Td>
            <Table.Td className="distribution-t-body total" colSpan={3}>
              {knownDeckCount}
            </Table.Td>
          </Table.Tr>
          {/* {sortedTableData?.map((data, i) => {
            console.log(data);
            return (
              <Table.Tr key={i}>
                <Table.Td className="distribution-t-body">{data.deck}</Table.Td>
                <Table.Td className="distribution-t-body">
                  {renderSprit(data)}
                </Table.Td>
                <Table.Td className="distribution-t-body">
                  {data.deckCount}
                </Table.Td>
                <Table.Td className="distribution-t-body">
                  {(
                    (data.deckCount / tournament.total_participants) *
                    100
                  ).toFixed(1)}
                  %
                </Table.Td>
              </Table.Tr>
            );
          })} */}
        </Table.Tbody>
      </Table>
    );
  };

  //check screen size to decide to render chart
  // if (useScreenSize().width <= 800) {
  //   return (
  //     <div>
  //       <div className="distribution-chart-div">
  //         <Title className="comp-title">Deck Distribution</Title>
  //         <Group justify="flex-end">
  //           <Select
  //             value={sort}
  //             onChange={setSort}
  //             data={[
  //               { value: "high", label: "Deck Count: High-Low" },
  //               { value: "low", label: "Deck Count: Low-High" },
  //               { value: "a", label: "Deck: A-Z" },
  //               { value: "z", label: "Deck: Z-A" },
  //             ]}
  //             className="distribution-sort"
  //             defaultValue="high"
  //             leftSection={<IconArrowsSort />}
  //             required
  //           />
  //         </Group>
  //         <div className="distribution-t mobile">{tableRender()}</div>
  //       </div>
  //     </div>
  //   );
  // } else {

  if (!dataFound) {
    return (
      <Title className="comp-title">
        No decks have been added to this tournament yet. Please check back
        later.
      </Title>
    );
  }

  return (
    <div>
      <div className="distribution-chart-div">
        <Group justify="space-between" className="distribution-title-div">
          <Title className="distribution-title">Deck Distribution</Title>
          <Select
            value={sort}
            onChange={setSort}
            data={[
              { value: "high", label: "Deck Count: High-Low" },
              { value: "low", label: "Deck Count: Low-High" },
              { value: "a", label: "Deck: A-Z" },
              { value: "z", label: "Deck: Z-A" },
            ]}
            className="distribution-sort"
            defaultValue="high"
            leftSection={<IconArrowsSort />}
            required
          />
        </Group>
        <Group justify="flex-start">
          <Text
            style={{
              fontSize: "12px",
              marginTop: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            *Please note that the information below could contain inaccuracies
            and may be subject to change
          </Text>
        </Group>
        {isMobile ? (
          <Group justify="center">
            <Text
              style={{
                fontSize: "12px",
                marginTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              *If the chart appears too small, consider enabling desktop view
              for an optimal viewing experience
            </Text>
          </Group>
        ) : null}
        <div className="distirbution-tabs-panel-div">
          <Text
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            Top {sortedChartData?.length} Used Decks
          </Text>
          {/* {pieRender()} */}
          {chartRender()}
          <XAxisSprites sortedChartData={sortedChartData} />
        </div>
        <Text
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "30px",
          }}
        >
          All Decks Used by {knownDeckCount}/{tournament.total_participants}{" "}
          Participants
        </Text>
        <div>{tableRender()}</div>
      </div>
    </div>
  );
  // }
};

export default DecksDistribution;
