const XAxisSprites = ({ sortedChartData }) => {
  //url for sprite api
  const spritePngUrl =
    "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Sprites%2FPokemon%2Fpng%2F";

  //get width of x axis
  const xAxisWidth = document.getElementById("x-axis-div")?.offsetWidth;

  //define style for the div of sprites
  const spritesDivStyle = {
    marginLeft: `${((xAxisWidth / sortedChartData?.length) * 15) / 100}px`,
    marginRight: `${((xAxisWidth / sortedChartData?.length) * 15) / 100}px`,
    // aspectRatio: "1/3",
  };

  const ifSpriteW = () => {
    if (((xAxisWidth / sortedChartData?.length) * 70) / 100 - 65 > 0) {
      return (((xAxisWidth / sortedChartData?.length) * 70) / 100 - 65) / 2;
    } else {
      return 0;
    }
  };

  const spriteDivStyle = {
    width: `${((xAxisWidth / sortedChartData?.length) * 70) / 100}px`,
    maxWidth: "65px",
    marginLeft: `${ifSpriteW()}px`,
    marginRight: `${ifSpriteW()}px`,
    aspectRatio: "1/1",
    borderRadius: "50%",
    position: "relative",
  };

  const spriteBtmDivStyle = {
    width: `${((xAxisWidth / sortedChartData?.length) * 50) / 100}px`,
    maxWidth: "50px",
    aspectRatio: "1/1",
    borderRadius: "50%",
    position: "relative",
  };

  return (
    <div className="x-axis-div" id="x-axis-div">
      {sortedChartData?.map((deck, i) => {
        return (
          <div className="x-axis-sprite-div" key={i} style={spritesDivStyle}>
            <div className="x-axis-sprite-img-div" style={spriteDivStyle}>
              <a className="basic-anchor" href={`/card/${deck?.card1Id}`}>
                <img
                  src={`${spritePngUrl}${deck?.card1Sprite}.png?alt=media`}
                  id="sprite"
                  alt="sprtie"
                  className="x-axis-sprite"
                />
              </a>
            </div>
            {deck?.card2Sprite ? (
              <div className="x-axis-sprite-img-div" style={spriteBtmDivStyle}>
                <a className="basic-anchor" href={`/card/${deck?.card2Id}`}>
                  <img
                    src={`${spritePngUrl}${deck?.card2Sprite}.png?alt=media`}
                    id="sprite"
                    alt="sprtie"
                    className="x-axis-sprite"
                  />
                </a>
              </div>
            ) : null}
            {deck?.card3Sprite ? (
              <div className="x-axis-sprite-img-div" style={spriteBtmDivStyle}>
                <a className="basic-anchor" href={`/card/${deck?.card3Id}`}>
                  <img
                    src={`${spritePngUrl}${deck?.card3Sprite}.png?alt=media`}
                    id="sprite"
                    alt="sprtie"
                    className="x-axis-sprite"
                  />
                </a>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default XAxisSprites;
