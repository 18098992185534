import { Button, Group, Text, Title } from "@mantine/core";

import "./HomeTop.css";
import useScreenSize from "../../hooks/useScreenSize";
import HomeCards from "./HomeCards";
import { IconChevronRight } from "@tabler/icons-react";

const HomeTop = () => {
  const screenSize = useScreenSize();

  if (screenSize.width < 900) {
    return <HomeCards />;
  }

  return (
    <div className="home-top-div">
      <Group className="home-top-grp" grow justify="center" gap="s">
        <div className="home-top-card left">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fmart.webp?alt=media"
            alt="bulbasaur"
            className="home-top-img left"
          />
          <div className="home-top-content-div left">
            <Title className="home-top-content-title">Stores</Title>
            <Text className="home-top-content-txt">
              Our list of TCG and board games retailers, with information, in
              Malaysia
            </Text>
            <a href="/stores" style={{ textDecoration: "none" }}>
              <Button
                unstyled
                className="home-top-content-btn"
                radius="xl"
                rightSection={<IconChevronRight color="#ffffff" size={20} />}
              >
                Check out our list now
              </Button>
            </a>
          </div>
        </div>
        <div className="home-top-card mid">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fred_pikachu.png?alt=media"
            alt="red"
            className="home-top-mid-back"
          />
          <div className="home-top-mid-line first"></div>
          <div className="home-top-mid-line second"></div>
          <div className="home-top-mid-line third"></div>
          <div className="home-top-mid-line fourth"></div>
          <div className="home-top-mid-line fifth"></div>
          <div className="home-top-mid-line sixth"></div>
          <div className="home-top-content-mid btm">
            <Title className="home-top-content-title mid">Articles</Title>
            <Text className="home-top-content-txt mid">
              Analyses, interviews, and snippets on the recent Pokémon TCG news
            </Text>
            <a href="/articles" style={{ textDecoration: "none" }}>
              <Button
                unstyled
                className="home-top-content-btn mid"
                radius="xl"
                rightSection={<IconChevronRight color="#ffffff" size={20} />}
              >
                Head to articles section
              </Button>
            </a>
          </div>
        </div>
        <div className="home-top-card right">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fgym.png?alt=media"
            alt="bulbasaur"
            className="home-top-img right"
          />
          <div className="home-top-content-div right">
            <Title className="home-top-content-title">Tournaments</Title>
            <Text className="home-top-content-txt">
              Check out the data on past and upcoming Pokémon TCG tournaments in
              Malaysia
            </Text>
            <a href="/tournaments" style={{ textDecoration: "none" }}>
              <Button
                unstyled
                className="home-top-content-btn"
                radius="xl"
                rightSection={<IconChevronRight color="#ffffff" size={20} />}
              >
                Take me there
              </Button>
            </a>
          </div>
        </div>
      </Group>
    </div>
  );
};

export default HomeTop;
