import { Table, Tooltip } from "@mantine/core";
import { Accordion } from "react-bootstrap";

import "./Store.css";
import gamesIcon from "../../functions/games/gamesIcon";
import gamesLabel from "../../lists/games/gamesLabel";
import storeLink from "./storeLink";
import { IconPencil } from "@tabler/icons-react";

//render store accordion title and content
const Store = ({ store, setStoreMap, eventKey, userDoc }) => {
  //working hour table
  const workingHour = (name, day) => {
    return (
      <Table.Tr>
        <Table.Td className="store-table-title">{name}</Table.Td>
        <Table.Td className="store-table-content">
          {day !== "closed" ? day : "Closed"}
        </Table.Td>
      </Table.Tr>
    );
  };

  //render game icon
  const gameIcon = (game, key) => {
    return (
      <Tooltip label={gamesLabel(game)} key={key}>
        <img src={gamesIcon(game)} alt="game" className="store-game-img" />
      </Tooltip>
    );
  };

  //handler for change map to store
  const handleAccordionExpandEvent = () => {
    setStoreMap(
      `https://www.google.com/maps/embed/v1/place?key=${
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      }&q=${store.name.replace("&", "")},Malaysia&zoom=13`
    );
  };

  //handler to change map to default
  const handleAccordionCollapseEvent = () => {
    setStoreMap(
      `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=Malaysia&&zoom=4&center=3.642709, 108.418838`
    );
  };

  return (
    <div>
      <Accordion.Item className="store-accordion" eventKey={eventKey}>
        <Accordion.Header>
          {store?.name} {store.cardGames?.map((game, i) => gameIcon(game, i))}
        </Accordion.Header>
        <Accordion.Body
          className="store-body"
          onEntering={handleAccordionExpandEvent}
          onExit={handleAccordionCollapseEvent}
        >
          {userDoc?.role === "admin" ? (
            <a className="basic-anchor" href={`/edit/store/${store.uid}`}>
              <IconPencil size="40" color="red" className="admin-new-btn" />
            </a>
          ) : (
            ""
          )}
          <Table className="store-table">
            <Table.Tbody>
              <Table.Tr>
                <Table.Td className="store-table-title">
                  <p>Location</p>
                </Table.Td>
                <Table.Td className="store-table-content">
                  {store.location?.address}
                </Table.Td>
              </Table.Tr>
              {store?.phone && (
                <Table.Tr>
                  <Table.Td className="store-table-title">
                    <p>Phone</p>
                  </Table.Td>
                  <Table.Td className="store-table-content">
                    {store?.phone}
                  </Table.Td>
                </Table.Tr>
              )}
              <Table.Tr>
                <Table.Td className="store-table-title">
                  <p>Working Hours</p>
                </Table.Td>
                <Table.Td className="store-table-content">
                  {!store?.workingHours ? (
                    "Working hours not found / closed"
                  ) : (
                    <Table>
                      <Table.Tbody>
                        {workingHour("Sunday", store.workingHours?.sun)}
                        {workingHour("Monday", store.workingHours?.mon)}
                        {workingHour("Tuesday", store.workingHours?.tue)}
                        {workingHour("Wednesday", store.workingHours?.wed)}
                        {workingHour("Thursday", store.workingHours?.thu)}
                        {workingHour("Friday", store.workingHours?.fri)}
                        {workingHour("Saturday", store.workingHours?.sat)}
                      </Table.Tbody>
                    </Table>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td className="store-table-title">
                  <p>Links</p>
                </Table.Td>
                <Table.Td className="store-table-content">
                  {storeLink(store?.socialMedia)}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

export default Store;
