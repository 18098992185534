import { useEffect, useState } from "react";
import contentService from "../../services/content";
import { IconPoint } from "@tabler/icons-react";

const CallBackend = () => {
  const [serverStatus, setServerStatus] = useState(
    <IconPoint color="blue" size="7" />
  );

  useEffect(() => {
    const params = {};
    const interval = setInterval(async () => {
      contentService
        .get({ route: "health", params: params, timeout: 5000 })
        .then((res) => {
          if (res.status === "OK") {
            setServerStatus(<IconPoint color="blue" size="7" />);
          }
        })
        .catch((err) => {
          setServerStatus(<IconPoint color="red" size="7" />);
        });
    }, 10 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div className="server-status">{serverStatus}</div>;
};

export default CallBackend;
