import { Text } from "@mantine/core";
import EnergyTooltip from "../games/pokemon/EnergyTooltip";
import "./CardSkills.css";

const CardSkills = ({ card }) => {
  const pkmTable = () => {
    return (
      <div>
        {!card?.abilities ? (
          ""
        ) : (
          <div>
            <div className="card-skills-section-title-div">
              <Text className="card-skills-section-title">Ability</Text>
              <div className="card-skills-section-title-border top" />
              <div className="card-skills-section-title-border btm" />
            </div>
            <div>
              {card?.abilities?.map((ability, i) => (
                <div key={i} className="card-atk-div">
                  <div>
                    <span className="card-atk-name">{ability.name}</span>
                  </div>
                  <div className="card-atk-text">{ability.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!card?.attacks ? (
          ""
        ) : (
          <div>
            <div className="card-skills-section-title-div">
              <Text className="card-skills-section-title">Attack(s)</Text>
              <div className="card-skills-section-title-border top" />
              <div className="card-skills-section-title-border btm" />
            </div>
            <div>
              {card?.attacks?.map((atk, i) => (
                <div key={i} className="card-atk-div">
                  <div>
                    <EnergyTooltip
                      energy={atk.cost}
                      name="card-skills-energy"
                    />
                    <span className="card-atk-name">{atk.name}</span>
                    {atk.damage ? (
                      <span className="card-atk-dmg">{atk.damage}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-atk-text">{atk.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const trainerTable = () => {
    return (
      <div>
        <div className="card-skills-section-title-div">
          <Text className="card-skills-section-title">Effect</Text>
          <div className="card-skills-section-title-border top" />
          <div className="card-skills-section-title-border btm" />
        </div>
        <div className="card-atk-text">{card?.rules[0]}</div>
      </div>
    );
  };

  const energyTable = () => {
    return (
      <div>
        <div className="card-skills-section-title-div">
          <Text className="card-skills-section-title">Effect</Text>
          <div className="card-skills-section-title-border top" />
          <div className="card-skills-section-title-border btm" />
        </div>
        <div className="card-atk-text">{card?.rules[0]}</div>
      </div>
    );
  };

  const tableRender = () => {
    switch (card?.supertype) {
      case "Pokémon":
        return pkmTable();
      case "Trainer":
        return trainerTable();
      case "Energy":
        return energyTable();
      default:
        return "";
    }
  };

  return <div className="card-skills-div">{tableRender()}</div>;
};

export default CardSkills;
