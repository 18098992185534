import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import useScreenSize from "./hooks/useScreenSize";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";

import Header from "./components/headerFooter/Header";
import AddInfo from "./pages/userAuth/AddInfo";
import Home from "./pages/home/Home";
import Stores from "./pages/stores/Stores";
import Articles from "./pages/articles/Articles";
import Article from "./pages/articles/Article";
import Cardsets from "./pages/cardsets/Cardsets";
import Cardset from "./pages/cardsets/Cardset";
import Card from "./pages/cards/Card";
import Policy from "./pages/policy/Policy";
import Footer from "./components/headerFooter/Footer";
import { auth } from "./firebase";
import { setToken } from "./services/token";
import userService from "./services/user";

import "./App.css";
import GenericResult from "./pages/search/GenericResult";
import Profile from "./pages/user/Profile";
import AddNew from "./pages/admin/AddNew";
import Tournaments from "./pages/tournaments/Tournaments";
import Edit from "./pages/admin/Edit";
import Top from "./components/adhoc/Top";
// import VerticalGAds from "./components/ads/VerticalGAds";
import About from "./pages/about/About";
import PastTournaments from "./pages/tournaments/PastTournaments";
import LinkSprite from "./pages/admin/link/LinkSprite";
import PastTournament from "./pages/tournaments/PastTournament";
import EditProfilePage from "./pages/user/EditProfilePage";
import {
  BannerAd300,
  BannerAd728,
  SocialBar,
} from "./components/ads/Adsterra/Ads";
import AppBackground from "./components/background/AppBackgrond";
import Support from "./pages/support/Support";

const App = () => {
  // const Wrapper = ({ children }) => {
  //   const location = useLocation();
  //   useLayoutEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [location.pathname]);
  //   return children;
  // };

  const navigate = useNavigate();
  const screenSize = useScreenSize();

  // console.log(screenSize);

  const [user] = useAuthState(auth);
  const [userDoc, setUserDoc] = useState({});
  const [userPrivateDoc, setUserPrivateDoc] = useState({});
  const [allUsersDoc, setAllUsersDoc] = useState(null);
  // const [globalGame, setGlobalGame] = useState("pokemon");
  // const [globalLang, setGlobalLang] = useState("en");

  //fetch user doc
  useEffect(() => {
    const fetchUserDoc = async () => {
      if (user) {
        try {
          const res = await userService.getUserDoc(user);
          setUserDoc(res.data());
        } catch (err) {
          console.error(err.message);
          alert(err.message);
        }
      }
    };
    fetchUserDoc();
  }, [user]);

  useEffect(() => {
    userService.getAllUsersDoc().then((res) => setAllUsersDoc(res));
  }, []);

  //fetch user private doc
  useEffect(() => {
    const fetchUserPriavteDoc = async () => {
      if (user) {
        try {
          const res = await userService.getUserPrivateDoc(user);
          setUserPrivateDoc(res.data());
        } catch (err) {
          console.error(err.message);
          alert(err.message);
        }
      }
    };
    fetchUserPriavteDoc();
  }, [user]);

  //check if user is new
  useEffect(() => {
    if (user) {
      userService.checkAdditionalInfo(user).then((res) => {
        if (res === false) {
          navigate("/addInfo");
        }
      });
    }
  }, [user, navigate]);

  //set user token
  useEffect(() => {
    if (user) {
      setToken(user?.accessToken);
    }
  }, [user]);

  // const adsToShow = () => {
  //   if (screenSize.width >= 1600) {
  //     return <VerticalGAds adSlot1="7202840234" adSlot2="9377631333" />;
  //   }
  // };

  const renderTopAds = () => {
    // if (screenSize.width < 728) {
    //   return <BannerAd300 />;
    // } else {
    //   return <BannerAd728 className="banner-ad" />;
    // }

    return <BannerAd728 className="banner-ad" />;
  };

  return (
    <div className="app-div">
      <AppBackground />
      {/* <SocialBar /> */}
      {/* <UserDrawer /> */}
      {/* {adsToShow()} */}
      <Header user={user} userDoc={userDoc} />
      <div className="main-body">
        <div className="top-app-div">{renderTopAds()}</div>
        {/* <Wrapper> */}
        <Routes>
          {/* public access */}
          {/* home */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          {/* profile */}
          <Route
            exact
            path="/profile/:uid"
            element={
              <Profile
                user={user}
                userDoc={userDoc}
                userPrivateDoc={userPrivateDoc}
              />
            }
          />
          <Route exact path="/addinfo" element={<AddInfo user={user} />} />
          <Route
            exact
            path="/profile/edit"
            element={
              <EditProfilePage
                user={user}
                userDoc={userDoc}
                userPrivateDoc={userPrivateDoc}
              />
            }
          />
          {/* search */}
          <Route exact path="/search/:term/:type" element={<GenericResult />} />
          {/* stores */}
          <Route exact path="/stores" element={<Stores userDoc={userDoc} />} />
          {/* cards */}
          <Route exact path="/cardsets" element={<Cardsets />} />
          <Route exact path="/cardsets/cardset/:id" element={<Cardset />} />
          <Route exact path="/card/:id" element={<Card userDoc={userDoc} />} />
          {/* tournaments */}
          <Route exact path="/tournaments" element={<Tournaments />} />
          <Route
            exact
            path="/tournaments/past"
            element={<PastTournaments userDoc={userDoc} />}
          />
          <Route
            exact
            path="/tournaments/past/:id"
            element={<PastTournament user={user} />}
          />
          {/* articles */}
          <Route
            exact
            path="/articles"
            element={<Articles userDoc={userDoc} />}
          />
          <Route
            exact
            path="/articles/article/:id"
            element={
              <Article
                user={user}
                allUsersDoc={allUsersDoc}
                userDoc={userDoc}
              />
            }
          />
          {/* about */}
          <Route exact path="about" element={<About />} />
          {/* admin access */}
          <Route
            exact
            path="/new/:type"
            element={<AddNew user={user} userDoc={userDoc} />}
          />
          <Route
            exact
            path="/edit/:type/:id"
            element={<Edit user={user} userDoc={userDoc} />}
          />
          <Route
            exact
            path="/link/sprite"
            element={<LinkSprite userDoc={userDoc} />}
          />{" "}
          <Route exact path="/policy" element={<Policy />} />
          <Route exact path="/support" element={<Support />} />
        </Routes>
        {/* </Wrapper> */}
      </div>
      <Top />
      <Footer />
    </div>
  );
};

export default App;
