import { Group, Burger } from "@mantine/core";
import { useState } from "react";
import { isBrowser } from "react-device-detect";

import "./Header.css";
import UserDrawer from "../user/UserDrawer";
import UserAuth from "../userAuth/UserAuth";
import useScrollDirection from "../../hooks/useScrollDirection";
import GenericSearch from "./GenericSearch";
import NavDrawer from "./NavDrawer";
const userIcon =
  "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2FuserLogo.png?alt=media";

//header component
const Header = ({ user, userDoc }) => {
  const scrollDirection = useScrollDirection();

  //handle user drawer's state and open
  const [showUserDrawer, setShowUserDrawer] = useState(false);
  const openUserDrawer = () => setShowUserDrawer(true);
  const closeUserDrawer = () => setShowUserDrawer(false);

  //handle mobile drawer's state and open
  const [showNavDrawer, setShowNavDrawer] = useState(false);
  const openNavDrawer = () => setShowNavDrawer(true);
  const closeNavDrawer = () => setShowNavDrawer(false);

  //handle user auth modal's state and open
  const [openedModal, setOpenedModal] = useState(false);
  const openModal = () => setOpenedModal(true);
  const closeModal = () => setOpenedModal(false);

  //handle state of user auth's component
  const [userAuthComp, setUserAuthComp] = useState("login");

  return (
    <div className={`header ${scrollDirection === "down" ? "hide" : "show"}`}>
      <UserAuth
        openedModal={openedModal}
        closeModal={closeModal}
        comp={userAuthComp}
        setComp={setUserAuthComp}
      />
      <UserDrawer
        user={user}
        userDoc={userDoc}
        showDrawer={showUserDrawer}
        closeDrawer={closeUserDrawer}
        openModal={openModal}
        setComp={setUserAuthComp}
      />
      <NavDrawer showModal={showNavDrawer} closeModal={closeNavDrawer} />
      <div
        className={`header-div ${scrollDirection === "down" ? "hide" : "show"}`}
      >
        <Group id="nav-link-group">
          <Burger
            opened={showNavDrawer}
            onClick={openNavDrawer}
            onClose={closeNavDrawer}
            className="nav-burger"
            color="#dddddd"
          />
          <a href="/" id="nav-link-timmy">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Logo%2FTimmy%2Ftimmy-logo-long.png?alt=media"
              alt="logo"
              className="timmy-logo"
            />
          </a>
        </Group>
        <Group id="nav-user-group">
          {isBrowser ? <GenericSearch /> : ""}
          <img
            src={userIcon}
            alt="user-icon"
            id="nav-user-icon"
            onClick={openUserDrawer}
          />
        </Group>
      </div>
    </div>
  );
};

export default Header;
