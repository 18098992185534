import {
  Box,
  Button,
  Group,
  Modal,
  Table,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandGoogle,
  IconBrandFacebook,
  IconMail,
  IconUser,
  IconCards,
  IconBuildingStore,
  IconPhotoEdit,
} from "@tabler/icons-react";
import "./Profile.css";
import { useState } from "react";
import UploadUserImg from "./UploadUserImg";

//render connected accounts
const Accounts = ({ provider }) => {
  switch (provider) {
    case "google":
      return (
        <Tooltip label="Google">
          <IconBrandGoogle size="20" />
        </Tooltip>
      );
    case "facebook":
      return (
        <Tooltip label="Facebook">
          <IconBrandFacebook size="20" />
        </Tooltip>
      );
    case "email":
      return (
        <Tooltip label="E-mail">
          <IconMail size="20" />
        </Tooltip>
      );
    default:
      return "";
  }
};

//render table for user profile
const ProfileTable = ({ userDoc, userPrivateDoc }) => {
  return (
    <Table.Tbody>
      <Table.Tr>
        <Table.Td className="profile-info-table left">Name</Table.Td>
        <Table.Td className="profile-info-table right">{`${userDoc?.firstName} ${userDoc?.lastName}`}</Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td className="profile-info-table left">Phone</Table.Td>
        <Table.Td className="profile-info-table right">
          {userPrivateDoc?.phoneNumber}
        </Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td className="profile-info-table left">E-mail</Table.Td>
        <Table.Td className="profile-info-table right">
          {userPrivateDoc?.email}
        </Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td className="profile-info-table left">Address</Table.Td>
        <Table.Td className="profile-info-table right">
          {`${userPrivateDoc?.address}, ${userPrivateDoc?.address2}, ${userPrivateDoc?.postcode} ${userPrivateDoc?.city}, ${userPrivateDoc?.state}.`}
        </Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td className="profile-info-table left">
          Connected Accounts
        </Table.Td>
        <Table.Td className="profile-info-table right">
          <Group>
            {userDoc?.authProvider?.map((provider, i) => (
              <Accounts provider={provider} key={i} />
            ))}
          </Group>
        </Table.Td>
      </Table.Tr>
    </Table.Tbody>
  );
};

//render profile page for current user
const SelfProfile = ({ userDoc, userPrivateDoc, user }) => {
  const [openedModal, setOpenedModal] = useState(false);
  const openModal = () => setOpenedModal(true);
  const closeModal = () => setOpenedModal(false);

  return (
    <div>
      <Modal
        centered
        opened={openedModal}
        onClose={closeModal}
        radius="lg"
        className="upload-user-img-modal"
      >
        <UploadUserImg user={user} userDoc={userDoc} />
      </Modal>
      <Tabs defaultValue="profile" color="red">
        <Tabs.List>
          <Tabs.Tab
            value="profile"
            className="profile-tabs-tab"
            leftSection={<IconUser />}
          >
            My Profile
          </Tabs.Tab>
          <Tabs.Tab
            value="decks"
            className="profile-tabs-tab"
            leftSection={<IconCards />}
            disabled
          >
            My Decks
          </Tabs.Tab>
          <Tabs.Tab
            value="listings"
            className="profile-tabs-tab"
            leftSection={<IconBuildingStore />}
            disabled
          >
            My Listings
          </Tabs.Tab>
        </Tabs.List>
        <div className="profile-tabs-panel-div">
          <Tabs.Panel value="profile">
            <Group justify="center" gap="0">
              <Box className="profile-name-box">
                <div className="profile-photo-div">
                  <img
                    src={userDoc?.photoURL}
                    alt="profile"
                    className="profile-photo"
                  />
                  <div className="profile-photo-edit-logo">
                    <IconPhotoEdit
                      size={30}
                      color="black"
                      onClick={openModal}
                    />
                  </div>
                </div>
                <Text className="profile-name">{userDoc?.name}</Text>
                <Button className="profile-edit-btn" radius="xl" disabled>
                  Edit Profile
                </Button>
              </Box>
              <Box className="profile-info-box">
                <Table>
                  <ProfileTable
                    userDoc={userDoc}
                    userPrivateDoc={userPrivateDoc}
                  />
                </Table>
              </Box>
            </Group>
          </Tabs.Panel>
        </div>
      </Tabs>
    </div>
  );
};

export default SelfProfile;
