import {
  Drawer,
  Button,
  Box,
  Title,
  CloseButton,
  Group,
  Text,
} from "@mantine/core";
import { logout } from "../../firebase";
import "./UserDrawer.css";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const UserDrawer = ({
  user,
  userDoc,
  showDrawer,
  closeDrawer,
  openModal,
  setComp,
}) => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    closeDrawer();
    openModal();
  };

  const handleRegisterClick = () => {
    setComp("register");
    closeDrawer();
    openModal();
  };

  const handleProfileClick = () => {
    closeDrawer();
    return navigate(`/profile/${user?.uid}`);
  };

  if (!user) {
    return (
      <Drawer.Root
        className="user-drawer"
        opened={showDrawer}
        onClose={closeDrawer}
        position="right"
        size="md"
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Body className="user-drawer-content">
            {isMobile ? (
              <Group justify="right">
                <CloseButton
                  variant="transparent"
                  size="xl"
                  onClick={closeDrawer}
                  className="user-drawer-close"
                />
              </Group>
            ) : (
              ""
            )}
            <Button className="user-drawer-btn" onClick={handleLoginClick}>
              Login
            </Button>
            <div className="user-drawer-text" onClick={handleRegisterClick}>
              Don't have an account? Register here
            </div>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    );
  } else if (user) {
    return (
      <Drawer.Root
        className="user-drawer"
        opened={showDrawer}
        onClose={closeDrawer}
        position="right"
        size="md"
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Body className="user-drawer-content">
            {isMobile ? (
              <Group justify="right">
                <CloseButton
                  variant="transparent"
                  size="xl"
                  onClick={closeDrawer}
                  className="user-drawer-close"
                />
              </Group>
            ) : (
              ""
            )}
            <Box>
              <img src={userDoc?.photoURL} alt="user" id="user-drawer-icon" />
              <Title className="user-drawer-name">{userDoc?.name}</Title>
            </Box>
            <Box className="user-drawer-btn-box">
              {userDoc?.role === "admin" ? (
                <Text
                  className="user-drawer-btn"
                  onClick={() =>
                    navigator.clipboard.writeText(`${user?.accessToken}`)
                  }
                >
                  Copy Token
                </Text>
              ) : null}
              <Button
                className="user-drawer-btn"
                fullWidth
                onClick={handleProfileClick}
              >
                View Profile
              </Button>
            </Box>
            <Button
              className="user-drawer-logout-btn"
              onClick={logout}
              fullWidth
            >
              Logout
            </Button>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    );
  }
};

export default UserDrawer;
