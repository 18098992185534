import { useEffect, useState } from "react";
import "./AppBackground.css";

const AppBackground = () => {
  const [img, setImg] = useState("town.webp");

  const url = window.location.href;

  useEffect(() => {
    if (url.includes("stores")) {
      setImg("pokemart.webp");
    } else if (url.includes("tournaments")) {
      setImg("arena.webp");
    } else if (url.includes("articles")) {
      setImg("articles.webp");
    } else if (url.includes("cardsets" || "card")) {
      setImg("lab.webp");
    } else {
      setImg("town.webp");
    }
  }, [url]);

  return (
    <img
      src={`https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Background%2F${img}?alt=media`}
      alt="bg"
      className="appBgImg"
    />
  );
};

export default AppBackground;
