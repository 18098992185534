import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import contentService from "../../services/content";
import { Box, Group, Skeleton, Text, Title } from "@mantine/core";
import { dayDate } from "../../functions/adhoc/dateTimeFormat";
import DecksDistribution from "../../components/tournaments/DecksDistribution";
import Loading from "../../components/adhoc/Loading";
import {
  IconCalendarEvent,
  IconLink,
  IconMapPinFilled,
} from "@tabler/icons-react";
import Disqus from "../../components/disqus/Disqus";
// import { NativeAds } from "../../components/ads/Adsterra/Ads";

const PastTournament = ({ user }) => {
  const id = useParams().id;

  const [tournamentData, setTournamentData] = useState({});
  const [loadingTournament, setLoadingTournament] = useState(false);
  const [loadingDecks, setLoadingDecks] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartOthers, setChartOthers] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [knownDeckCount, setKnownDeckCount] = useState(0);
  const [maxDeckCount, setMaxDeckCount] = useState(0);

  useEffect(() => {
    //load tournament data
    setLoadingTournament(true);
    try {
      contentService.get({ route: `tournaments/${id}` }).then((res) => {
        setLoadingTournament(false);
        setTournamentData(res);
      });
    } catch (err) {
      console.error(err.message);
    }

    //load deck distri
    setLoadingDecks(true);
  }, [id]);

  useEffect(() => {
    contentService
      .get({ route: `tournaments/${id}/deck-distribution` })
      .then((res) => {
        setLoadingDecks(false);

        //check if tournament has decks
        if (res.length > 0) {
          const sortedRes = res
            ?.filter((deck) => deck.deck_name !== "Unknown")
            ?.sort((a, b) => (a.deck > b.deck ? 1 : -1))
            ?.sort((a, b) => (a.deck_count > b.deck_count ? -1 : 1));

          setDataFound(true);

          let chartOrgData = [];
          let tableOrgData = [];
          let sum = 0;

          const max = 0;

          const maxCount = sortedRes.reduce(
            (max, deck) => (max > deck.deck_count ? max : deck.deck_count),
            max
          );

          const oneCountDecks = res.filter((deck) => deck.deck_count === 1);

          sortedRes
            ?.slice(0, res?.length < 10 ? res.length : 10)
            ?.filter((deck) => deck.deck_count !== 1)
            ?.filter((deck) => deck.deck_name !== "Unknown")
            .forEach((deck) => {
              chartOrgData.push({
                deck: deck.deck_name,
                card1Id: deck.card1_id,
                card2Id: deck.card2_id,
                card3Id: deck.card3_id,
                card1Sprite: deck.sprite1,
                card2Sprite: deck.sprite2,
                card3Sprite: deck.sprite3,
                deckCount: deck.deck_count,
              });
            });

          sortedRes?.forEach((deck) => {
            sum += deck.deck_count;
            tableOrgData.push({
              deck: deck.deck_name,
              card1Id: deck.card1_id,
              card2Id: deck.card2_id,
              card3Id: deck.card3_id,
              card1Sprite: deck.sprite1,
              card2Sprite: deck.sprite2,
              card3Sprite: deck.sprite3,
              deckCount: deck.deck_count,
            });
          });
          setChartData(chartOrgData);
          setChartOthers(oneCountDecks.length);
          setTableData(tableOrgData);
          setKnownDeckCount(sum);
          setMaxDeckCount(maxCount);
        } else if (res.length === 0) {
          setDataFound(false);
        }
      })
      .catch((err) => console.error(err.message));
  }, [id]);

  const logoToShow = () => {
    const imgUrl = (type) =>
      `https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2FPTCG%2FType%2F${type}.png?alt=media`;

    switch (tournamentData?.type) {
      case "Gym Event":
        return imgUrl("Gym");
      case "Town League":
        return imgUrl("Town");
      case "Regional League":
        return imgUrl("Regional");
      case "Championship":
        return imgUrl("Championship");
      case "Others":
        return imgUrl("Others");
      default:
        return "";
    }
  };

  const tournamentLogoStyle = {
    width: "90%",
  };

  return (
    <div className="page-div">
      {loadingTournament || loadingDecks ? <Loading /> : null}
      <div
        style={{
          backgroundColor: "#30475ece",
          background: "linear-gradient(to bottom, #4b79a1, #283e51)",
          padding: "20px",
          boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
          color: "white",
        }}
      >
        <Box align="center">
          {loadingTournament || loadingDecks ? (
            <div>
              <Skeleton height={150} circle />
              <Skeleton
                height={40}
                width="50%"
                className="page-title"
                style={{ marginTop: "20px" }}
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  padding: "10px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  width: "150px",
                  aspectRatio: "1/1",
                  boxShadow:
                    " 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11)",
                }}
              >
                <img
                  src={logoToShow()}
                  alt="logo"
                  style={tournamentLogoStyle}
                />
              </div>
              <Group justify="center">
                <IconMapPinFilled size={40} style={{ marginRight: "5px" }} />
                <Title
                  className="page-title"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                    fontFamily: "Jakarta Sans",
                  }}
                >
                  {tournamentData?.location}
                </Title>
              </Group>
            </div>
          )}
        </Box>
        <Group justify="center">
          {loadingTournament || loadingDecks ? (
            <Skeleton width="20%" height={30} style={{ marginTop: "20px" }} />
          ) : (
            <Group justify="center">
              <IconCalendarEvent />
              <Text className="page-date">{dayDate(tournamentData?.date)}</Text>
            </Group>
          )}
        </Group>
        {tournamentData?.link ? (
          <Group justify="center">
            {loadingTournament || loadingDecks ? (
              <Skeleton width="40%" height={30} style={{ marginTop: "20px" }} />
            ) : (
              <Group align="center" justify="center">
                <IconLink
                  size={15}
                  style={{
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                />
                <Text
                  className="page-date"
                  style={{
                    cursor: "pointer",
                    fontSize: "15px",
                    marginTop: "10px",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.open(tournamentData?.link, "_blank", "noreferrer");
                  }}
                >
                  {tournamentData?.link}
                </Text>
              </Group>
            )}
          </Group>
        ) : null}
      </div>
      <DecksDistribution
        tournament={tournamentData}
        loadingDecks={loadingDecks}
        dataFound={dataFound}
        chartData={chartData}
        tableData={tableData}
        chartOthers={chartOthers}
        knownDeckCount={knownDeckCount}
        maxDeckCount={maxDeckCount}
      />
      {/* <NativeAds /> */}
      <div style={{ marginTop: "20px" }}>
        <Disqus title={`${tournamentData?.location}@${tournamentData?.date}`} />
      </div>
    </div>
  );
};

export default PastTournament;
