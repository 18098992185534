import { Button, Loader, TextInput } from "@mantine/core";
import { useState } from "react";
import contentService from "../../../../services/content";

const AddTrainer = ({
  trainersList,
  setTrainersList,
  closeModal,
  setModalToShow,
}) => {
  const [createTrainer, setCreateTrainer] = useState({
    name: "",
    trainer_website_name: "",
    trainer_website_id: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  //handle add trainer submit
  const trainerCreate = (e) => {
    e.preventDefault();

    const trainerInfo = {
      name: createTrainer.name ? createTrainer.name : null,
      trainer_website_name: createTrainer.trainer_website_name
        ? createTrainer.trainer_website_name
        : null,
      trainer_website_id: createTrainer.trainer_website_id,
    };

    setSubmitLoading(true);

    try {
      contentService
        .create({
          route: "trainers",
          newObject: trainerInfo,
        })
        .then((res) => {
          setSubmitLoading(false);
          setTrainersList([
            ...trainersList,
            {
              value: res.uid,
              label: `${trainerInfo.trainer_website_name} (${trainerInfo.trainer_website_id})`,
            },
          ]);
        })
        .then(() => {
          closeModal();
          setCreateTrainer({
            name: "",
            trainer_website_name: "",
            trainer_website_id: "",
          });
          setModalToShow("");
        });
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="new-div">
      <form onSubmit={trainerCreate} className="new-form">
        <TextInput
          label="Name"
          value={createTrainer.name}
          onChange={(e) =>
            setCreateTrainer({
              ...createTrainer,
              name: e.target.value,
            })
          }
          className="new-input long"
        />
        <TextInput
          label="Trainer Website Name"
          value={createTrainer.trainer_website_name}
          onChange={(e) =>
            setCreateTrainer({
              ...createTrainer,
              trainer_website_name: e.target.value,
            })
          }
          className="new-input long"
        />
        <TextInput
          label="Trainer Website ID"
          value={createTrainer.trainer_website_id}
          onChange={(e) =>
            setCreateTrainer({
              ...createTrainer,
              trainer_website_id: e.target.value,
            })
          }
          className="new-input long"
          required
        />
        {!submitLoading ? (
          <Button type="submit" className="new-submit-btn">
            Submit
          </Button>
        ) : (
          <Button className="new-submit-btn">
            <Loader color="white" />
          </Button>
        )}
      </form>
    </div>
  );
};

export default AddTrainer;
