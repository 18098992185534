import { useEffect, useState } from "react";
import { Group, Loader, Modal, Select, Text, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

import contentService from "../../../../services/content";
import AddDeck from "./AddDeck";
import AddTournament from "./AddTournament";
import AddTrainer from "./AddTrainer";
import AddTrainerDecks from "./AddTrainerDecks";
import { date } from "../../../../functions/adhoc/dateTimeFormat";

import "../AdminForm.css";

const EditTournament = ({ userDoc }) => {
  /******* states *******/
  const [tournamentsList, setTournamentsList] = useState([]);
  const [trainersList, setTrainersList] = useState([]);
  const [tournamentId, setTournamentId] = useState("");

  /******* load lists *******/
  //get tournaments
  useEffect(() => {
    if (userDoc?.role === "admin") {
      contentService.get({ route: "tournaments/get-latest" }).then((res) => {
        const sortedList = res?.sort((a, b) => (a.date < b.date ? 1 : -1));
        let data = [];
        sortedList?.forEach((tournament) => {
          data.push({
            value: tournament.id,
            label: `${tournament.type} @ ${tournament.location} (${date(
              tournament.date
            )})`,
          });
        });
        setTournamentsList(data);

        contentService.get({ route: "trainers/get-all" }).then((res) => {
          let data = [];
          res?.forEach((trainer) => {
            data.push({
              value: trainer.id,
              label: `${trainer.trainer_website_name} (${trainer.trainer_website_id})`,
            });
          });
          setTrainersList(data);
        });
      });
    }
  }, [userDoc]);

  /******* modal handler *******/
  const [modalToShow, setModalToShow] = useState("");
  const [openedModal, setOpenedModal] = useState(false);

  //modal open handler
  const openModal = () => setOpenedModal(true);
  const closeModal = () => setOpenedModal(false);

  //handle what modal to show
  const showModal = () => {
    switch (modalToShow) {
      case "tournament":
        return (
          <AddTournament
            closeModal={closeModal}
            setModalToShow={setModalToShow}
            tournamentsList={tournamentsList}
            setTournamentsList={setTournamentsList}
          />
        );
      case "trainer":
        return (
          <AddTrainer
            closeModal={closeModal}
            setModalToShow={setModalToShow}
            trainersList={trainersList}
            setTrainersList={setTrainersList}
          />
        );
      case "deck":
        return (
          <AddDeck closeModal={closeModal} setModalToShow={setModalToShow} />
        );
      default:
        return "";
    }
  };

  //handle open modal click
  const openModalClick = (type) => {
    setModalToShow(type);
    openModal();
  };

  return (
    <div>
      <Modal opened={openedModal} onClose={closeModal} centered zIndex={300}>
        {showModal()}
      </Modal>
      <Select
        label="Tournament"
        className="new-input long"
        data={tournamentsList}
        value={tournamentId}
        onChange={setTournamentId}
        searchable
        required
        disabled={
          tournamentsList.length === 0 || trainersList.length === 0
            ? true
            : false
        }
      />
      <Text
        className="new-add-link"
        onClick={() => openModalClick("tournament")}
      >
        Add a New Tournament
      </Text>
      <Text className="new-add-link" onClick={() => openModalClick("trainer")}>
        Add a New Trainer
      </Text>
      <Text className="new-add-link" onClick={() => openModalClick("deck")}>
        Add a New Deck
      </Text>
      <a className="new-add-link" href="/link/sprite">
        Link Sprite to Card
      </a>
      <div>
        {tournamentsList.length === 0 ? (
          <Group>
            <Text>Tournaments Loading</Text>
            <Loader type="dots" color="black" size="14" />
          </Group>
        ) : (
          <Group>
            <Text>Tournaments Loaded</Text>
            <IconCheck color="green" />
          </Group>
        )}
        {trainersList.length === 0 ? (
          <Group>
            <Text>Trainers Loading</Text>
            <Loader type="dots" color="black" size="14" />
          </Group>
        ) : (
          <Group>
            <Text>Trainers Loaded</Text>
            <IconCheck color="green" />
          </Group>
        )}
      </div>
      {!tournamentId ? (
        tournamentsList.length === 0 || trainersList.length === 0 ? (
          <Title style={{ textAlign: "center", marginTop: "20px" }}>
            Loading tournaments and trainers lists...
          </Title>
        ) : (
          <Title style={{ textAlign: "center", marginTop: "20px" }}>
            Please select a tournament first
          </Title>
        )
      ) : (
        <AddTrainerDecks
          trainersList={trainersList}
          tournamentId={tournamentId}
        />
      )}
    </div>
  );
};

export default EditTournament;
