import { Button, FileInput, Skeleton, Title } from "@mantine/core";
import { useState } from "react";

import "./UploadUserImg.css";
import { uploadUserImg } from "../../firebase";
import userService from "../../services/user";

const UploadUserImg = ({ user, userDoc }) => {
  const [imgToUpload, setImgToUplaod] = useState();

  const uploadImg = (e) => {
    e.preventDefault();

    const fileType = () => {
      if (imgToUpload.name.includes("png")) {
        return "png";
      } else if (imgToUpload.name.includes("jpeg")) {
        return "jpeg";
      } else if (imgToUpload.name.includes("jpg")) {
        return "jpg";
      }
    };

    const imgName = `${user?.uid}.${fileType()}`;

    const img = new File([imgToUpload], imgName, { type: imgToUpload.type });

    try {
      uploadUserImg(img, imgName).then((res) => {
        const updatedUserDoc = { ...userDoc, photoURL: res };
        userService.updateUserDoc(user, updatedUserDoc).then(() => {
          window.location.reload();
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="upload-user-img-div">
      <Title className="upload-user-img-title">Edit Your Profile Photo</Title>
      {imgToUpload ? (
        <img
          src={URL.createObjectURL(imgToUpload)}
          alt="user"
          className="user-photo"
        />
      ) : (
        <Skeleton circle height={200} className="user-photo" />
      )}
      <FileInput
        label="Select a Photo"
        description="png, jpeg, jpg images only"
        required
        className="upload-user-img-input"
        placeholder="Select a Photo"
        value={imgToUpload}
        onChange={setImgToUplaod}
        accept="image/png,image/jpeg,image/jpg"
      />
      <Button
        className="upload-user-img-btn"
        onClick={uploadImg}
        disabled={imgToUpload ? false : true}
      >
        Submit
      </Button>
    </div>
  );
};

export default UploadUserImg;
