import { Badge, Card, Group, Text, Title } from "@mantine/core";

import "./Tournaments.css";

const Tournaments = () => {
  return (
    <div className="page-div">
      <Title className="page-title">Tournamets in Malaysia</Title>
      <Group justify="center" className="tournaments-card-grp">
        <a href="/tournaments/past" className="basic-anchor">
          <Card
            className="tournaments-card"
            shadow="none"
            padding="lg"
            radius="md"
            withBorder
            // onClick={() => (window.location.href = "/tournaments/past")}
          >
            <Card.Section>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Tournaments%2FCard%20Img%2Fchart-icon-color.png?alt=media"
                alt="chart"
                width="350"
                height="150"
                className="tournaments-card-img"
              />
            </Card.Section>
            <div className="tournaments-card-info-div">
              <Group
                justify="space-between"
                className="tournaments-card-title-grp"
              >
                <Title className="tournaments-card-title">
                  Past Tournaments
                </Title>
              </Group>
              <Text className="tournaments-card-text">
                Check out our data of every major and minor tournaments all
                around Malaysia
              </Text>
            </div>
          </Card>
        </a>
        <Card
          className="tournaments-card disabled"
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
        >
          <Card.Section>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Tournaments%2FCard%20Img%2F1426735.png?alt=media"
              alt="chart"
              width="350"
              height="150"
              className="tournaments-card-img"
            />
          </Card.Section>
          <div className="tournaments-card-info-div">
            <Group
              justify="space-between"
              className="tournaments-card-title-grp"
              gap="0"
            >
              <Title className="tournaments-card-title">
                Upcoming Tournaments
              </Title>
              <Badge color="pink" variant="light" style={{ fontSize: "10px" }}>
                Coming Soon
              </Badge>
            </Group>
            <Text className="tournaments-card-text">
              Want to know which tournaments are coming up next in Malaysia?
              Click here to find out more
            </Text>
          </div>
        </Card>
      </Group>
    </div>
  );
};

export default Tournaments;
