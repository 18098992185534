const DayDate = ({ unix }) => {
  const dateTime = new Date(unix * 1000);
  const option = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const format = dateTime.toLocaleDateString("en-US", option);
  return format;
};

export default DayDate;
