import { Group, Tooltip } from "@mantine/core";
import {
  IconBrandShopee,
  IconBrandFacebook,
  IconWorld,
} from "@tabler/icons-react";
import IconLazada from "./icons/lazada.png";

const storeLink = (link) => {
  return (
    <Group>
      {link?.official ? (
        <Tooltip label="Official Website">
          <IconWorld
            className="store-link-icon"
            onClick={() => {
              window.open(link?.official, "_blank", "noreferrer");
            }}
          />
        </Tooltip>
      ) : (
        ""
      )}
      {link?.fb ? (
        <Tooltip label="Facebook">
          <IconBrandFacebook
            className="store-link-icon"
            onClick={() => {
              window.open(link?.fb, "_blank", "noreferrer");
            }}
          />
        </Tooltip>
      ) : (
        ""
      )}
      {link?.shopee ? (
        <Tooltip label="Shopee">
          <IconBrandShopee
            className="store-link-icon"
            onClick={() => {
              window.open(link?.shopee, "_blank", "noreferrer");
            }}
          />
        </Tooltip>
      ) : (
        ""
      )}
      {link?.lazada ? (
        <Tooltip label="Lazada">
          <img
            src={IconLazada}
            alt="lazada"
            className="store-link-icon"
            onClick={() => {
              window.open(link?.lazada, "_blank", "noreferrer");
            }}
          />
        </Tooltip>
      ) : (
        ""
      )}
    </Group>
  );
};

export default storeLink;
