import { spriteIds } from "./spriteIds";

let spriteSelectData = [{ value: "", label: "None" }];

for (const id in spriteIds) {
  spriteSelectData.push({
    label: spriteIds[id],
    value: id,
  });
}

export default spriteSelectData;
