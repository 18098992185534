import { useState } from "react";
import {
  loginWithEmail,
  loginWithFacebook,
  loginWithGoogle,
} from "../../firebase";
import { Input, Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAt, IconPassword, IconLogin } from "@tabler/icons-react";

import "./UserAuth.css";

const Login = ({ setComp, googleIcon, facebookIcon }) => {
  const [credentials, setCreadentials] = useState({
    email: "",
    password: "",
  });

  //login handler
  const handleLogin = (e) => {
    e.preventDefault();

    if (!credentials.email) {
      notifications.show({
        title: "Error",
        message: "Please enter a valid e-mail",
        color: "red",
      });
    } else if (!credentials.password) {
      notifications.show({
        title: "Error",
        message: "Please enter your password",
        color: "red",
      });
    } else if (
      credentials.password.length < 8 ||
      credentials.password.length > 16
    ) {
      notifications.show({
        title: "Error",
        message: "Password must be between 8 to 16 characters",
        color: "red",
      });
    } else {
      loginWithEmail(credentials.email, credentials.password);
    }
  };

  //check to disable login button if credentials are not found
  const loginBtnDisabled = () => {
    if (!credentials.email || !credentials.password) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="user-auth-body-content">
      <IconLogin color="#f05454" size="50px" />
      <h2 className="user-auth-title">Login</h2>
      <form onSubmit={(e) => handleLogin(e)}>
        <Input
          placeholder="E-mail Address"
          value={credentials.email}
          onChange={(e) =>
            setCreadentials({ ...credentials, email: e.target.value })
          }
          type="email"
          required
          className="user-auth-input"
          leftSection={<IconAt />}
        />
        <Input
          placeholder="Password"
          value={credentials.password}
          onChange={(e) =>
            setCreadentials({ ...credentials, password: e.target.value })
          }
          type="password"
          required
          className="user-auth-input"
          leftSection={<IconPassword />}
        />
        <p className="user-auth-switch" onClick={() => setComp("reset")}>
          Reset password
        </p>
        <Button
          type="submit"
          className="user-auth-email-btn"
          disabled={loginBtnDisabled()}
          fullWidth
        >
          Login
        </Button>
      </form>
      <p className="user-auth-or">or</p>
      <Button
        className="user-auth-google-btn"
        leftSection={googleIcon}
        onClick={loginWithGoogle}
        fullWidth
      >
        Login with Google
      </Button>
      <Button
        className="user-auth-facebook-btn"
        leftSection={facebookIcon}
        onClick={loginWithFacebook}
        fullWidth
      >
        Login with Facebook
      </Button>
      <p className="user-auth-switch" onClick={() => setComp("register")}>
        Don't have an account? Register here
      </p>
    </div>
  );
};

export default Login;
