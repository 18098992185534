import { Box, CloseButton, Group, NavLink, Drawer } from "@mantine/core";
import "./NavDrawer.css";
import GenericSearch from "./GenericSearch";
import { isMobile } from "react-device-detect";
import {
  IconBuildingStore,
  IconInfoCircle,
  IconMail,
  IconNews,
  IconPlayCard,
  IconTournament,
} from "@tabler/icons-react";

const NavDrawer = ({ showModal, closeModal }) => {
  const handleNavClick = () => {
    closeModal();
  };

  return (
    <Drawer.Root
      opened={showModal}
      onClose={closeModal}
      className="nav-drawer"
      zIndex="1000"
      size="md"
    >
      <Drawer.Overlay className="nav-drawer-overlay" />
      <Drawer.Content className="nav-drawer-content">
        <Drawer.Body className="nav-drawer-body">
          {isMobile ? (
            <Group justify="right">
              <CloseButton
                size="xl"
                onClick={closeModal}
                className="nav-drawer-close"
                variant="transparent"
              />
            </Group>
          ) : (
            ""
          )}
          <a className="nav-drawer-timmy-logo-div" href="/">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Logo%2FTimmy%2Ftimmy-logo.png?alt=media"
              alt="logo"
              className="nav-drawer-timmy-logo"
              onClick={handleNavClick}
            />
          </a>
          <div className="nav-drawer-search">
            {isMobile ? <GenericSearch /> : ""}
          </div>
          <Box className="nav-drawer-box">
            <div className="nav-drawer-nav-div">
              <a
                className="nav-drawer-links-div"
                onClick={handleNavClick}
                href="/stores"
              >
                <div className="nav-drawer-links-border"></div>
                <NavLink
                  label="Stores"
                  className="nav-drawer-links main"
                  leftSection={<IconBuildingStore color="#ffffff" />}
                  // rightSection={<IconChevronRight color="#ffffff" />}
                />
              </a>
              <a
                className="nav-drawer-links-div"
                onClick={handleNavClick}
                href="/cardsets"
              >
                <div className="nav-drawer-links-border"></div>
                <NavLink
                  label="Cardsets"
                  className="nav-drawer-links main"
                  leftSection={<IconPlayCard color="#ffffff" />}
                  // rightSection={<IconChevronRight color="#ffffff" />}
                />
              </a>
              <a
                className="nav-drawer-links-div"
                onClick={handleNavClick}
                href="/tournaments"
              >
                <div className="nav-drawer-links-border"></div>
                <NavLink
                  label="Tournaments"
                  className="nav-drawer-links main"
                  leftSection={<IconTournament color="#ffffff" />}
                  // rightSection={<IconChevronRight color="#ffffff" />}
                />
              </a>
              <a
                className="nav-drawer-links-div"
                onClick={handleNavClick}
                href="/articles"
              >
                <div className="nav-drawer-links-border"></div>
                <NavLink
                  label="Articles"
                  className="nav-drawer-links main"
                  leftSection={<IconNews color="#ffffff" />}
                  // rightSection={<IconChevronRight color="#ffffff" />}
                />
              </a>
              <a
                className="nav-drawer-links-div"
                onClick={handleNavClick}
                href="/about"
              >
                <div className="nav-drawer-links-border"></div>
                <NavLink
                  label="About Us"
                  className="nav-drawer-links main"
                  leftSection={<IconInfoCircle color="#ffffff" />}
                  // rightSection={<IconChevronRight color="#ffffff" />}
                />
              </a>
              <a
                className="nav-drawer-links-div"
                onClick={handleNavClick}
                href="/support"
              >
                <div className="nav-drawer-links-border"></div>
                <NavLink
                  label="Support"
                  className="nav-drawer-links main"
                  leftSection={<IconMail color="#ffffff" />}
                  // rightSection={<IconChevronRight color="#ffffff" />}
                />
              </a>
            </div>
          </Box>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default NavDrawer;
