import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Group, Input, Select } from "@mantine/core";
import {
  IconSearch,
  IconDeviceGamepad2,
  IconMapPinFilled,
  IconCirclePlus,
} from "@tabler/icons-react";

import contentService from "../../services/content";
import Store from "../../components/stores/Store";
import { statesSelectList } from "../../lists/states/statesSelectList";
import { gamesSelectList } from "../../lists/games/gamesListStore";
import "./Stores.css";
// import { NativeAds } from "../../components/ads/Adsterra/Ads";

const accordionStyle = {
  backgroundColor: "#ffffff",
  "&[data-active]": {
    backgroundColor: "#ebfaff",
  },
};

const Stores = ({ userDoc }) => {
  const [stores, setStores] = useState([]);
  const [search, setSearch] = useState("");
  const [stateFilter, setStateFilter] = useState("All States");
  const [gameFilter, setGameFilter] = useState("All Games");
  const [storeMap, setStoreMap] = useState(
    `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=Malaysia&zoom=4&center=3.642709, 108.418838`
  );

  //fetch stores list
  useEffect(() => {
    contentService
      .get({ route: "stores/get-all" })
      .then((res) => setStores(res));
  }, []);

  //handle search bar to filter stores
  const handleSearch = () => {
    if (!search) {
      return stores;
    } else if (search) {
      return stores.filter((store) =>
        store.name?.toLowerCase().includes(search.toLowerCase())
      );
    }
  };

  //handle state filter
  const handleStateFilter = () => {
    if (stateFilter === "All States") {
      return handleSearch();
    } else {
      return handleSearch().filter((store) =>
        store.location.state?.includes(stateFilter)
      );
    }
  };

  //handle games and overall filter
  const handleGameFilter = () => {
    if (gameFilter === "All Games") {
      return handleStateFilter();
    } else {
      return handleStateFilter().filter((store) =>
        store.cardGames?.includes(gameFilter)
      );
    }
  };

  return (
    <div className="page-div">
      <h2 className="page-title">Stores in Malaysia</h2>
      {userDoc?.role === "admin" ? (
        <IconCirclePlus
          size="40"
          color="red"
          className="admin-new-btn"
          onClick={() => (window.location.href = "/new/store")}
        />
      ) : (
        ""
      )}
      <div id="stores-search-div">
        <Input
          leftSection={<IconSearch />}
          placeholder="Search for stores"
          radius="xl"
          size="md"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="stores-search-bar"
        />
      </div>
      <Group justify="center" id="stores-filter-div">
        <Select
          value={gameFilter}
          onChange={setGameFilter}
          data={gamesSelectList}
          defaultValue="All Games"
          radius="xl"
          className="stores-filter"
          leftSection={<IconDeviceGamepad2 />}
        />
        <Select
          value={stateFilter}
          onChange={setStateFilter}
          data={statesSelectList}
          defaultValue="All States"
          radius="xl"
          className="stores-filter"
          leftSection={<IconMapPinFilled />}
        />
      </Group>
      <Group justify="center" id="stores-content">
        <iframe
          title="google-map"
          id="store-map"
          referrerPolicy="no-referrer-when-downgrade"
          src={storeMap}
        ></iframe>
        <Accordion
          className="stores-accordion"
          styles={{
            item: accordionStyle,
          }}
        >
          {handleGameFilter()
            ?.sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            )
            .map((store, i) => (
              <Store
                store={store}
                key={i}
                setStoreMap={setStoreMap}
                eventKey={i}
                userDoc={userDoc}
              />
            ))}
        </Accordion>
      </Group>
      {/* <NativeAds /> */}
    </div>
  );
};

export default Stores;
