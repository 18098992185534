import { Modal } from "@mantine/core";

import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";

import "./UserAuth.css";
const googleIcon = (
  <img
    src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Fgoogle.png?alt=media"
    alt="google"
    className="user-auth-left-icon"
  />
);
const facebookIcon = (
  <img
    src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Ffacebook.png?alt=media"
    alt="google"
    className="user-auth-left-icon"
  />
);

const UserAuth = ({ openedModal, closeModal, comp, setComp }) => {
  //handle components to show
  const compToShow = () => {
    switch (comp) {
      case "login":
        return (
          <Login
            setComp={setComp}
            googleIcon={googleIcon}
            facebookIcon={facebookIcon}
          />
        );
      case "register":
        return (
          <Register
            setComp={setComp}
            googleIcon={googleIcon}
            facebookIcon={facebookIcon}
          />
        );
      case "reset":
        return <Reset setComp={setComp} />;
      default:
        return (
          <Login
            setComp={setComp}
            googleIcon={googleIcon}
            facebookIcon={facebookIcon}
          />
        );
    }
  };

  return (
    <div>
      <Modal.Root
        opened={openedModal}
        onClose={closeModal}
        className="user-auth-modal"
        centered
        radius="lg"
      >
        <Modal.Overlay />
        <Modal.Content className="user-auth-content">
          <Modal.Body className="user-auth-body">{compToShow()}</Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

export default UserAuth;
