import { Tooltip } from "@mantine/core";
import getEnergyLogo from "../../../functions/pokemon/getEnergyLogo";

const EnergyTooltip = ({ energy, name }) => {
  // if (energy.isArray()) {
  //   return energy?.map((type, i) => (
  //     <Tooltip label={type} key={i}>
  //       <img src={getEnergyLogo(type.toLowerCase())} alt="energy" />
  //     </Tooltip>
  //   ));
  // } else {
  //   return (
  //     <Tooltip label={energy}>
  //       <img src={getEnergyLogo(energy.toLowerCase())} alt="energy" />
  //     </Tooltip>
  //   );
  // }
  return energy?.map((type, i) => (
    <Tooltip label={type} key={i}>
      <img
        src={getEnergyLogo(type.toLowerCase())}
        alt="energy"
        className={name}
      />
    </Tooltip>
  ));
};

export default EnergyTooltip;
