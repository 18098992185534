import { Button, Group, Select, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { userStateList } from "../../../lists/states/userStateList";
import gamesList from "../../../lists/games/gamesListGlobal";
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import contentService from "../../../services/content";
import { useParams } from "react-router-dom";
import "./AdminForm.css";

const StoreForm = ({ mode }) => {
  const id = useParams().id;

  const [data, setData] = useState({
    name: "",
    phone: "",
    status: "",
    capacity: "",
  });
  const [location, setLocation] = useState({
    address: "",
    state: "",
    geo: {
      latitude: "",
      longitude: "",
    },
  });
  const [socialMedia, setSocialMedia] = useState({
    fb: "",
    official: "",
    shopee: "",
    lazada: "",
  });
  const [workingHours, setWorkingHours] = useState({
    sun: "",
    mon: "",
    tue: "",
    wed: "",
    thu: "",
    fri: "",
    sat: "",
  });
  const [cardGames, setCardGames] = useState([""]);

  useEffect(() => {
    if (mode === "edit") {
      contentService.get({ route: `stores/${id}` }).then((res) => {
        setData({
          name: res.name,
          phone: res.phone,
          capacity: res.capaity === null ? "" : res.capacity,
          status: res.status,
        });
        setLocation(res.location);
        setSocialMedia({
          official:
            res.socialMedia.official === null ? "" : res.socialMedia.official,
          fb: res.socialMedia.fb === null ? "" : res.socialMedia.fb,
          shopee: res.socialMedia.shopee === null ? "" : res.socialMedia.shopee,
          lazada: res.socialMedia.lazada === null ? "" : res.socialMedia.lazada,
        });
        setWorkingHours(res.workingHours);
        setCardGames(res.cardGames);
      });
    }
  }, [id, mode]);

  //render working hours form
  let renderWorkingHoursForm = [];
  for (const day in workingHours) {
    renderWorkingHoursForm.push(day);
  }
  //handle working hours input change
  const handleWorkingHoursChange = (day, e) => {
    let data = { ...workingHours };
    data[day] = e.target.value;
    setWorkingHours(data);
  };

  //render social media form
  let renderSocialMediaForm = [];
  for (const media in socialMedia) {
    renderSocialMediaForm.push(media);
  }
  //handle social media input change
  const handleSocialMediaChange = (media, e) => {
    let data = { ...socialMedia };
    data[media] = e.target.value;
    setSocialMedia(data);
  };

  //handle change to game input
  const handleCardGamesChange = (i, e) => {
    let data = [...cardGames];
    data[i] = e;
    setCardGames(data);
  };

  //handler to add more games
  const addGameSelect = () => {
    let newGame = "";
    setCardGames([...cardGames, newGame]);
  };

  //handler to delete game
  const delGameSelect = () => {
    let data = [...cardGames];
    data.pop();
    setCardGames(data);
  };

  //submit form handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const storeData = {
      name: data.name,
      phone: data.phone,
      status: data.status,
      capacity: data.capacity,
      location: {
        address: location.address,
        state: location.state,
        geo: {
          longitude: location.geo.longitude,
          latitude: location.geo.latitude,
        },
      },
      workingHours: {
        sun: workingHours.sun,
        mon: workingHours.mon,
        tue: workingHours.tue,
        wed: workingHours.wed,
        thu: workingHours.thu,
        fri: workingHours.fri,
        sat: workingHours.sat,
      },
      socialMedia: {
        official: socialMedia.official,
        fb: socialMedia.fb,
        shopee: socialMedia.shopee,
        lazada: socialMedia.lazada,
      },
      cardGames: cardGames,
    };

    try {
      //new store submit function
      if (mode === "add") {
        contentService
          .create({ route: "stores", newObject: storeData })
          .then(() => {
            notifications.show({
              title: "Success",
              message: `${data.name} has been added to articles`,
              color: "teal",
            });
          })
          .then(() => {
            setData({ name: "", phone: "", status: "", capacity: "" });
            setLocation({
              address: "",
              state: "",
              geo: {
                latitude: "",
                longitude: "",
              },
            });
            setSocialMedia({ fb: "", official: "", shopee: "", lazada: "" });
            setWorkingHours({
              sun: "",
              mon: "",
              tue: "",
              wed: "",
              thu: "",
              fri: "",
              sat: "",
            });
            setCardGames([""]);
          });
      }
      // edit store submit function
      else if (mode === "edit") {
        contentService
          .update({ route: `stores/${id}`, updatedObject: storeData })
          .then(() => {
            notifications.show({
              title: "Success",
              message: `${data.name} has been edited`,
              color: "teal",
            });
          });
      }
    } catch (err) {
      notifications.show({
        title: "Error",
        message: `An error has occured. ${data.name} has not been added to articles`,
        color: "red",
      });
      console.error(err.message);
    }
  };

  return (
    <div className="new-div">
      <form className="new-form" onSubmit={(e) => handleSubmit(e)}>
        <Group>
          <TextInput
            label="Name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            className="new-input"
            name="name"
            required
          />
          <TextInput
            label="Phone No."
            value={data.phone}
            onChange={(e) => setData({ ...data, phone: e.target.value })}
            className="new-input"
            name="phone"
            type="number"
            required
          />
          <Select
            label="Status"
            value={data.status}
            onChange={(e) => setData({ ...data, status: e })}
            data={[
              { label: "Active", value: "active" },
              { label: "Closed", value: "closed" },
              { label: "Unknown", value: "unknown" },
            ]}
            required
            className="new-input"
          />
          <TextInput
            label="Capacity"
            value={data.capacity}
            onChange={(e) => setData({ ...data, capacity: e.target.value })}
            className="new-input"
            name="capacity"
            type="number"
          />
        </Group>
        <div>
          <Text className="new-section-title">Location</Text>
          <Group>
            <TextInput
              label="Address"
              value={location.address}
              onChange={(e) =>
                setLocation({ ...location, address: e.target.value })
              }
              className="new-input long"
              name="address"
              required
            />
            <Select
              label="State"
              value={location.state}
              onChange={(e) => setLocation({ ...location, state: e })}
              data={userStateList}
              required
              className="new-input"
            />
            <TextInput
              label="Longitude"
              value={location.geo.longitude}
              onChange={(e) =>
                setLocation({
                  ...location,
                  geo: { ...location.geo, longitude: e.target.value },
                })
              }
              className="new-input"
              name="longitude"
              required
            />
            <TextInput
              label="Latitude"
              value={location.geo.latitude}
              onChange={(e) =>
                setLocation({
                  ...location,
                  geo: { ...location.geo, latitude: e.target.value },
                })
              }
              className="new-input"
              name="latitude"
              required
            />
          </Group>
        </div>
        <div>
          <Text className="new-section-title">Working Hours</Text>
          <Group>
            {renderWorkingHoursForm.map((day, i) => (
              <TextInput
                key={i}
                label={day}
                value={workingHours[day]}
                onChange={(e) => handleWorkingHoursChange(day, e)}
                className="new-input"
                name={day}
                required
              />
            ))}
          </Group>
        </div>
        <div>
          <Text className="new-section-title">Social Media</Text>
          <Group>
            {renderSocialMediaForm.map((media, i) => (
              <TextInput
                key={i}
                label={media}
                value={socialMedia[media]}
                onChange={(e) => handleSocialMediaChange(media, e)}
                className="new-input"
                name={media}
              />
            ))}
          </Group>
        </div>
        <div>
          <Text className="new-section-title">Card Games</Text>
          <Group>
            {cardGames.map((game, i) => (
              <Select
                key={i}
                placeholder={`Game ${i + 1}`}
                value={game}
                onChange={(e) => handleCardGamesChange(i, e)}
                data={gamesList()}
                required
                className="new-input"
              />
            ))}
            <IconCirclePlus
              color="green"
              className="new-dynamic-field-add-del"
              onClick={addGameSelect}
            />
            {cardGames.length === 1 ? (
              ""
            ) : (
              <IconCircleMinus
                color="red"
                className="new-dynamic-field-add-del"
                onClick={() => delGameSelect()}
              />
            )}
          </Group>
        </div>
        <Button type="submit" className="new-submit-btn" radius="xl">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default StoreForm;
