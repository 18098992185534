import { Title, Button, Box, Table, Text } from "@mantine/core";
import { IconCalendarEvent, IconHash, IconPencil } from "@tabler/icons-react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import DayDate from "../date/DayDate";
import "./ArticleView.css";
import Disqus from "../disqus/Disqus";

// const likeIcon =
//   "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Flike.png?alt=media";
// const likedIcon =
//   "https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2Fliked.png?alt=media";

const ArticleView = ({ article, uid, user, handleLike, handleUnlike }) => {
  // const likeBtn = () => {
  //   if (!user) {
  //     return "";
  //   } else if (user) {
  //     if (article?.likes?.includes(user?.uid)) {
  //       return (
  //         <img
  //           src={likedIcon}
  //           alt="liked"
  //           className="article-like-btn"
  //           onClick={handleUnlike}
  //         />
  //       );
  //     } else {
  //       return (
  //         <img
  //           src={likeIcon}
  //           alt="like"
  //           className="article-like-btn"
  //           onClick={handleLike}
  //         />
  //       );
  //     }
  //   }
  // };

  return (
    <div>
      <div className="article-title-div">
        <img
          className="article-title-img"
          src={article?.imgURL}
          atl="title"
          alt="article"
        />
        <Title id="article-title">{article?.title}</Title>
      </div>
      <Table gap="0" className="article-content-grp">
        <Table.Tbody>
          <Table.Tr>
            <Table.Th className="article-content-th left">
              <Box className="article-body">
                <ReactMarkdown
                  remarkPlugins={[gfm]}
                  rehypePlugins={[rehypeRaw]}
                  children={article?.body}
                  components={{
                    img: ({ node, ...props }) => (
                      <img
                        className="article-react-markdown-img"
                        {...props}
                        alt="markdown"
                      />
                    ),
                    p: ({ node, ...props }) => (
                      <p {...props} className="article-react-markdown-text" />
                    ),
                    h2: ({ node, ...props }) => (
                      <h2
                        {...props}
                        className="article-react-markdown-content-title-1"
                      >
                        {""}
                      </h2>
                    ),
                    h3: ({ node, ...props }) => (
                      <h3
                        {...props}
                        className="article-react-markdown-content-title-2"
                      >
                        {""}
                      </h3>
                    ),
                  }}
                />
              </Box>
            </Table.Th>
            <Table.Th className="article-content-th right">
              <div className="article-content-div">
                <div className="article-info">
                  <Box gap="5" justify="center" className="article-author-box">
                    <IconPencil color="#ffffff" size="30" />
                    <Text className="article-author">{article?.author}</Text>
                  </Box>
                  <Box gap="5" justify="center" className="article-author-box">
                    <IconCalendarEvent color="#ffffff" size="30" />
                    <Text className="article-author">
                      {<DayDate unix={article?.updatedAt} />}
                    </Text>
                  </Box>
                  {/* <Group justify="center" className="article-author-grp">
                    {likeBtn()} {article?.likes?.length}
                  </Group> */}
                </div>
                <div className="article-tags-div">
                  {article?.tags?.map((tag, i) => (
                    <a
                      className="basic-anchor"
                      href={`/search/${tag}/articles`}
                    >
                      <Button
                        key={i}
                        radius="xl"
                        variant="default"
                        leftSection={<IconHash />}
                        fullWidth
                        className="article-tag-btn"
                        justify="flex-start"
                        // onClick={() => navigate(`/search/${tag}/articles`)}
                      >
                        {tag}
                      </Button>
                    </a>
                  ))}
                </div>
              </div>
            </Table.Th>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      <Disqus title={article.title} />
    </div>
  );
};

export default ArticleView;
