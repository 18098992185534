import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import contentService from "../../services/content";
import ArticleView from "../../components/articles/ArticleView";
import "./Article.css";
import { IconPencil } from "@tabler/icons-react";
// import { NativeAds } from "../../components/ads/Adsterra/Ads";

const Article = ({ user, allUsersDoc, userDoc }) => {
  const [article, setArticle] = useState([]);
  const [articleComments, setArticleComments] = useState([]);

  //fetch uid
  const uid = useParams().id;

  //fetch article
  useEffect(() => {
    contentService.get({ route: `articles/${uid}` }).then((res) => {
      setArticle(res);
      setArticleComments(res.comments);
    });
  }, [uid]);

  //like handler
  const handleLike = async () => {
    if (!user) {
      console.error("Only logged in user can like this article");
    }

    try {
      contentService.update({ route: `articles/${uid}/like` });
      setArticle({ ...article, likes: [...article.likes, user?.uid] });
    } catch (err) {
      console.error(err.message);
    }
  };

  //unlike handler
  const handleUnlike = async () => {
    if (!user) {
      console.error("Only logged in user can unlike this article");
    }
    try {
      contentService.update({ route: `articles/${uid}/unlike` });
      setArticle({
        ...article,
        likes: article.likes.filter((uid) => uid !== user?.uid),
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="page-div">
      {userDoc?.role === "admin" ? (
        <a className="basic-anchor" href={`/edit/article/${uid}`}>
          <IconPencil
            size="40"
            color="red"
            className="admin-new-btn"
            // onClick={() => navigate(`/edit/article/${uid}`)}
          />
        </a>
      ) : (
        ""
      )}
      {/* <NativeAds /> */}
      <ArticleView
        article={article}
        uid={uid}
        user={user}
        handleLike={handleLike}
        handleUnlike={handleUnlike}
        allUsersDoc={allUsersDoc}
        userDoc={userDoc}
      />
      {/* <Disqus.DiscussionEmbed
        shortname="timmy-cards"
        config={disqusConfig}
        className="article-disqus"
      /> */}
      {/* <ArticleComments
        article={article}
        setArticle={setArticle}
        comments={articleComments}
        setComments={setArticleComments}
        allUsersDoc={allUsersDoc}
        user={user}
        uid={uid}
      /> */}
    </div>
  );
};

export default Article;
