import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import contentService from "../../services/content";
import { IconArrowsSort, IconAward } from "@tabler/icons-react";
import { Box, Grid, Group, Loader, Select, Text } from "@mantine/core";
import { Card } from "react-bootstrap";
import { date } from "../../functions/adhoc/dateTimeFormat";
import useScreenSize from "../../hooks/useScreenSize";

import "./PastTournamentsList.css";

const PastTournamentsList = () => {
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const [tournaments, setTournaments] = useState([]);
  const [type, setType] = useState("all");
  const [sort, setSort] = useState("dateNew");

  useEffect(() => {
    try {
      contentService
        .get({ route: "tournaments/get-latest" })
        .then((res) => setTournaments(res));
    } catch (err) {
      console.error(err.message);
    }
  }, []);

  const typeSelectData = [
    { value: "all", label: "All" },
    { value: "gym", label: "Gym Event" },
    { value: "town", label: "Town League" },
    { value: "regional", label: "Regional League" },
    { value: "championship", label: "Championship" },
    { value: "others", label: "Others" },
  ];

  const sortSelectData = [
    { value: "dateNew", label: "Date: Newest to Oldest" },
    { value: "dateOld", label: "Date: Oldest to Newest" },
    { value: "locationA", label: "Location: A to Z" },
    { value: "locationZ", label: "Location: Z to A" },
  ];

  const sortTournaments = () => {
    switch (sort) {
      case "dateNew":
        return tournaments?.sort((a, b) => (a.date > b.date ? -1 : 1));
      case "dateOld":
        return tournaments?.sort((a, b) => (a.date < b.date ? -1 : 1));
      case "locationA":
        return tournaments?.sort((a, b) =>
          a.location.toLowerCase() < b.location.toLowerCase() ? -1 : 1
        );
      case "locationZ":
        return tournaments?.sort((a, b) =>
          a.location.toLowerCase() > b.location.toLowerCase() ? -1 : 1
        );
      default:
        return tournaments?.sort((a, b) => (a.date > b.date ? -1 : 1));
    }
  };

  //tournament type filter
  const typeFilter = () => {
    switch (type) {
      case "all":
        return sortTournaments();
      case "gym":
        return sortTournaments()?.filter(
          (tournament) => tournament.type === "Gym Event"
        );
      case "town":
        return sortTournaments()?.filter(
          (tournament) => tournament.type === "Town League"
        );
      case "regional":
        return sortTournaments()?.filter(
          (tournament) => tournament.type === "Regional League"
        );
      case "championship":
        return sortTournaments()?.filter(
          (tournament) => tournament.type === "Championship"
        );
      case "others":
        return sortTournaments()?.filter(
          (tournament) => tournament.type === "Others"
        );
      default:
        return tournaments;
    }
  };

  const handleListClick = (id) => navigate(`/tournaments/past/${id}`);

  const renderTournamentsCard = () => {
    const imgUrl = (type) =>
      `https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/icon%2FPTCG%2FType%2F${type}.png?alt=media`;

    const imgToRender = (type) => {
      switch (type) {
        case "Gym Event":
          return imgUrl("Gym");
        case "Town League":
          return imgUrl("Town");
        case "Regional League":
          return imgUrl("Regional");
        case "Championship":
          return imgUrl("Championship");
        case "Others":
          return imgUrl("Others");
        default:
          return "";
      }
    };

    return typeFilter()?.map((tournament, i) => (
      <a
        href={`/tournaments/past/${tournament?.id}`}
        className="basic-anchor past-tourney-cards"
      >
        <Card
          key={i}
          className="past-tournaments-card"
          onClick={() => handleListClick(tournament.id)}
        >
          <Group justify="flex-start">
            <div className="past-tournaments-card-img-div">
              <img
                className="past-tournaments-card-img"
                src={imgToRender(tournament.type)}
                alt="type"
              />
            </div>
            <Box className="past-tournaments-card-box">
              <Text className="past-tournaments-card-loc">
                {tournament.location}
              </Text>
              <Text>{date(tournament.date)}</Text>
            </Box>
            <div className="past-tournaments-gif-div">
              <img
                className="past-tournaments-gif"
                src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Tournaments%2FGIF%2Fred.png?alt=media"
                alt="gengar"
              />
            </div>
          </Group>
        </Card>
      </a>
    ));
  };

  return (
    <div>
      <Group justify="center" className="past-tournaments-select-grp">
        <Select
          leftSection={<IconAward />}
          radius="xl"
          data={typeSelectData}
          value={type}
          onChange={setType}
          className="past-tournaments-select"
          allowDeselect={false}
        />{" "}
        <Select
          leftSection={<IconArrowsSort />}
          radius="xl"
          data={sortSelectData}
          value={sort}
          onChange={setSort}
          className="past-tournaments-select"
          allowDeselect={false}
        />
      </Group>
      {tournaments?.length === 0 ? (
        <Loader
          type="dots"
          color="black"
          style={{ margin: "auto", marginTop: "20px" }}
        />
      ) : (
        <div className="past-tournamets-cards-grp">
          {renderTournamentsCard()}
        </div>
      )}
    </div>
  );
};

export default PastTournamentsList;
