import { Button, Grid, Group, Text, Title } from "@mantine/core";

import "./AboutContent.css";

const AboutContent = () => {
  return (
    <div className="about-contents-div">
      <div className="about-header-div">
        <div className="about-logo-div">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Logo%2FTimmy%2Ftimmy-logo.png?alt=media"
            alt="logo"
            className="about-logo"
          />
        </div>
      </div>
      <Group className="about-content-div" justify="space-evenly">
        <div span={5} className="about-grid-col first left">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/adhoc%2Fpokemon%2FCharmander.jpeg?alt=media"
            alt="background"
            className="about-background-img"
          />
          <div className="about-content-info">
            <Title className="about-content-title">Our Mission</Title>
            <Text className="about-content-text">
              At TimmyCards, we're all about connecting the vibrant Pokémon TCG
              community in Malaysia. Our mission is simple: to be your go-to
              source for all things Pokémon TCG-related, from the latest
              tournament news to helping you find your dream cards.
            </Text>
          </div>
        </div>
        <div span={5} className="about-grid-col first right">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/adhoc%2Fpokemon%2FPikachu.jpeg?alt=media"
            alt="background"
            className="about-background-img"
          />
          <div className="about-content-info">
            <Title className="about-content-title">Who We Are</Title>
            <Text className="about-content-text">
              We're not just another website. We're a passionate team of Pokémon
              TCG enthusiasts who call Malaysia home. Our love for the game,
              collecting, and the camaraderie it fosters led us to create
              TimmyCards. We understand the thrill of a well-played game, the
              excitement of finding that rare card, and the joy of being part of
              a community that shares your passion.
            </Text>
          </div>
        </div>
      </Group>
      <div className="about-content-div second">
        <Title className="about-content-title second">What We Offer</Title>
        <Grid justify="space-evenly" gutter="0" className="about-grid second">
          <Grid.Col span={6} className="about-grid-col second">
            <Text className="about-content-text">
              Stay in the know with our up-to-date coverage of Pokémon TCG
              events, strategies, and trends in Malaysia. Whether you're a
              seasoned player or just starting your journey, we've got you
              covered.
            </Text>
          </Grid.Col>
          <Grid.Col span={6} className="about-grid-col flip">
            <div className="about-flip-inner">
              <div className="grid-second-front">
                <Title className="about-content-subtitle">Information</Title>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/About%2Flatest_pkm.png?alt=media"
                  alt="latest"
                  className="about-content-img"
                />
              </div>
              <div className="grid-second-back btn">
                <a className="basic-anchor about-btn" href="/tournaments">
                  <Button className="about-flip-btn">Tournament Info</Button>
                </a>
                <a className="basic-anchor about-btn" href="/articles">
                  <Button className="about-flip-btn">Articles</Button>
                </a>
              </div>
            </div>
          </Grid.Col>
        </Grid>
        <Grid justify="space-evenly" gutter="0" className="about-grid second">
          <Grid.Col span={6} className="about-grid-col flip">
            <div className="about-flip-inner">
              <div className="grid-second-front">
                <Title className="about-content-subtitle">Trading</Title>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/About%2Ftrade-pkm.webp?alt=media&"
                  alt="latest"
                  className="about-content-img"
                />
              </div>
              <div className="grid-second-back">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/About%2Fcoming-soon.jpeg?alt=media"
                  alt="latest"
                  className="about-content-img flip"
                />
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={6} className="about-grid-col second">
            <Text className="about-content-text">
              Looking to complete your collection or make some swaps? TimmyCards
              is your one-stop marketplace for trading Pokémon TCG cards.
              Connect with fellow trainers, strike up deals, and fill those gaps
              in your collection.
            </Text>
          </Grid.Col>
        </Grid>
      </div>{" "}
      {/* <div className="about-content-div">
        <Title className="about-content-title">Join Us</Title>
        <Text className="about-content-text">
          We're more than just a website; we're a community. Join TimmyCards and
          become part of a network of Pokémon TCG fans across Malaysia. Connect
          with fellow trainers, share your experiences, and embark on this
          fantastic journey with us.
        </Text>
      </div> */}
    </div>
  );
};

export default AboutContent;
