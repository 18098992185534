import { Button, Group, Select, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";

import "./EditProfile.css";
import { userStateList } from "../../lists/states/userStateList";

const genderData = ["Male", "Female", "Others"];

const EditProfile = ({ user, userDoc, userPrivateDoc }) => {
  const [doc, setDoc] = useState({});
  const [privateDoc, setPrivateDoc] = useState({});
  // const [imgToUpload, setImgToUpload] = useState("");

  useEffect(() => {
    setDoc(userDoc);
    setPrivateDoc(userPrivateDoc);
  }, [userDoc, userPrivateDoc]);

  const handleDocChg = (e, type) => {
    setDoc({ ...doc, [type]: e.target.value });
  };

  const handlePrivateDocChg = (e, type) => {
    setPrivateDoc({ ...privateDoc, [type]: e.target.value });
  };

  console.log(doc, privateDoc);
  return (
    <div className="edit-profile-div">
      <form>
        <TextInput
          className="edit-profile-input"
          label="Display Name"
          required
          value={doc?.name || ""}
          onChange={(e) => handleDocChg(e, "name")}
        />
        <Group justify="center" grow>
          <TextInput
            className="edit-profile-input"
            label="First Name"
            required
            value={doc?.firstName || ""}
            onChange={(e) => handleDocChg(e, "firstName")}
          />
          <TextInput
            className="edit-profile-input"
            label="Last Name"
            required
            value={doc?.lastName || ""}
            onChange={(e) => handleDocChg(e, "lastName")}
          />
        </Group>
        <Group justify="center" grow>
          <TextInput
            className="edit-profile-input"
            label="Phone Number"
            required
            value={privateDoc?.phoneNumber || ""}
            onChange={(e) => handlePrivateDocChg(e, "phoneNumber")}
          />
          <Select
            className="edit-profile-input"
            label="Gender"
            required
            value={doc?.gender || ""}
            onChange={(e) => setDoc({ ...doc, gender: e })}
            data={genderData}
            allowDeselect={false}
          />
        </Group>
        <TextInput
          className="edit-profile-input"
          label="Address 1"
          required
          value={privateDoc?.address || ""}
          onChange={(e) => handlePrivateDocChg(e, "address")}
        />
        <TextInput
          className="edit-profile-input"
          label="Address 2"
          required
          value={privateDoc?.address2 || ""}
          onChange={(e) => handlePrivateDocChg(e, "address2")}
        />
        <Group justify="center" grow>
          <TextInput
            className="edit-profile-input"
            label="City"
            required
            value={privateDoc?.city || ""}
            onChange={(e) => handlePrivateDocChg(e, "city")}
          />
          <TextInput
            className="edit-profile-input"
            label="Postcode"
            required
            value={privateDoc?.postcode || ""}
            type="number"
            onChange={(e) => handlePrivateDocChg(e, "postcode")}
          />
        </Group>
        <Select
          className="edit-profile-input"
          label="State"
          required
          value={privateDoc?.state || ""}
          onChange={(e) => setPrivateDoc({ ...privateDoc, state: e })}
          data={userStateList}
          allowDeselect={false}
        />
        <Group justify="center">
          <Button className="edit-profile-submit">Submit</Button>
        </Group>
      </form>
    </div>
  );
};

export default EditProfile;
