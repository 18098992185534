import { Text, Title } from "@mantine/core";
import { useParams } from "react-router-dom";
import StoreForm from "../../components/admin/forms/StoreForm";
import ArticleForm from "../../components/admin/forms/ArticleForm";
import EditTournament from "../../components/admin/forms/tournament/EditTourament";

const Edit = ({ userDoc }) => {
  const type = useParams().type;

  const handleTitle = (type) => {
    switch (type) {
      case "article":
        return "Article";
      case "store":
        return "Store";
      case "tournaments":
        return "Tournaments";
      default:
        return "";
    }
  };

  const formToRender = () => {
    if (type === "article") {
      return <ArticleForm mode="edit" />;
    } else if (type === "store") {
      return <StoreForm mode="edit" />;
    } else if (type === "tournaments") {
      return <EditTournament userDoc={userDoc} />;
    }
  };

  return (
    <div className="page-div">
      {userDoc?.role !== "admin" ? (
        <Text className="warning">
          You do not have the permission to view this page
        </Text>
      ) : (
        <div>
          <Title className="page-title">{`Edit ${handleTitle(type)}`}</Title>
          {formToRender()}
        </div>
      )}
    </div>
  );
};

export default Edit;
