import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import contentService from "../../services/content";
import "./LatestHome.css";
import { Grid, Group, Loader, Paper, Text, Title } from "@mantine/core";

const LatestHome = () => {
  const navigate = useNavigate();

  // const [latestArticles, setLatestArticles] = useState([]);
  const [latestCardsets, setLatestCardsets] = useState([]);

  // //callback to fetch latest articles
  // useEffect(() => {
  //   const fetchLatestArticles = () => {
  //     const params = { page: 1 };
  //     try {
  //       contentService
  //         .get({ route: `articles/get-latest`, params: params })
  //         .then((res) => setLatestArticles(res));
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   };

  //   fetchLatestArticles();
  // }, []);

  //callback to fetch latest cardsets
  useEffect(() => {
    const fetchLatestCardsets = () => {
      const params = { card_type: "pokemon", language: "en" };
      try {
        contentService
          .get({ route: "cardsets/get-all", params: params })
          .then((res) => {
            let cardsets = res?.slice(-11);
            cardsets?.reverse();
            setLatestCardsets(cardsets);
          });
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchLatestCardsets();
  }, []);

  return (
    <div className="latest-home-div">
      {/* <Paper className="latest-home-paper">
        <Grid className="latest-home-grid">
          <Grid.Col span="auto" className="latest-home-title">
            Latest
            <br />
            Articles
          </Grid.Col>
          <Grid.Col span={9} className="latest-home-content">
            {latestArticles?.length === 0 ? (
              <Loader color="#30475e" className="latest-home-loader" />
            ) : (
              ""
            )}
            {latestArticles?.map((article, i) => {
              return (
                <p
                  className="latest-home-list"
                  key={i}
                  onClick={() => navigate(`/articles/article/${article.uid}`)}
                >
                  {article.title}
                </p>
              );
            })}
          </Grid.Col>
        </Grid>
      </Paper> */}
      <Title className="latest-home-title">Latest Pokémon Cardsets</Title>
      <Paper className="latest-home-paper">
        <div className="latest-home-paper-border top"></div>
        <div className="latest-home-paper-border btm"></div>
        {/* <div className="latest-home-paper-bgd"></div> */}
        <Grid className="latest-home-grid" gutter="0">
          <Grid.Col span="auto" className="latest-home-top">
            <a
              className="latest-home-cardset-div basic-anchor"
              href={`/cardsets/cardset/${latestCardsets[0]?.id}`}
            >
              <div className="latest-home-cardset-border top"></div>
              {latestCardsets.length < 1 ? (
                <Loader color="#30475e" className="latest-home-loader" />
              ) : (
                <img
                  src={latestCardsets[0]?.images?.logo}
                  alt="cardset"
                  className="latest-home-top-img"
                />
              )}

              <Text className="latest-home-top-text">
                {latestCardsets[0]?.name}
              </Text>
            </a>
          </Grid.Col>
          <Grid.Col span={9} className="latest-home-content">
            {latestCardsets?.length === 0 ? (
              <Loader color="#30475e" className="latest-home-loader" />
            ) : (
              ""
            )}
            <div>
              <Group
                justify="space-evenly"
                className="latest-home-cardsets-grp "
              >
                {latestCardsets?.slice(1, 6)?.map((cardset, i) => {
                  return (
                    <a
                      className="latest-home-cardset-div list basic-anchor"
                      key={i}
                      href={`/cardsets/cardset/${cardset?.id}`}
                    >
                      <div className="latest-home-cardset-border btm"></div>
                      <img
                        src={cardset?.images?.logo}
                        alt="cardset"
                        className="latest-home-cardsets-card-img"
                      />
                      <Text className="latest-home-top-text list">
                        {cardset?.name}
                      </Text>
                    </a>
                  );
                })}
              </Group>
              <Group
                justify="space-evenly"
                className="latest-home-cardsets-grp "
              >
                {latestCardsets?.slice(6, 11)?.map((cardset, i) => {
                  return (
                    <div
                      className="latest-home-cardset-div list"
                      key={i}
                      onClick={() =>
                        navigate(`/cardsets/cardset/${cardset?.id}`)
                      }
                    >
                      <div className="latest-home-cardset-border btm"></div>
                      <img
                        src={cardset.images?.logo}
                        alt="cardset"
                        className="latest-home-cardsets-card-img"
                      />
                      <Text className="latest-home-top-text list">
                        {cardset?.name}
                      </Text>
                    </div>
                  );
                })}
              </Group>
            </div>
            {/* {latestCardsets?.slice(1)?.map((cardset, i) => {
              return (
                <Group
                  key={i}
                  className="latest-home-list-grp"
                  onClick={() => navigate(`/cardsets/cardset/${cardset.id}`)}
                >
                  <img
                    src={cardset.images.logo}
                    className="latest-home-sym"
                    alt="icon"
                  />
                  <p className="latest-home-list">{cardset.name}</p>
                </Group>
              );
            })} */}
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  );
};

export default LatestHome;
