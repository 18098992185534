import { useParams } from "react-router-dom";
import "./Profile.css";
import SelfProfile from "../../components/user/SelfProfile";
import OtherProfile from "../../components/user/OhterProfile";

const Profile = ({ user, userDoc, userPrivateDoc }) => {
  const id = useParams().uid;

  if (user?.uid === id) {
    return (
      <div className="page-div">
        <SelfProfile
          userDoc={userDoc}
          userPrivateDoc={userPrivateDoc}
          user={user}
        />
      </div>
    );
  } else {
    return (
      <div className="page-div">
        <OtherProfile id={id} />
      </div>
    );
  }
};

export default Profile;
