import { Text, Title } from "@mantine/core";
import "./Support.css";

const Support = () => {
  return (
    <div className="page-div support">
      <Title className="support-title">Support</Title>
      <Text className="support-txt">
        Email us at:{" "}
        <a href="mailto:timmy.cards.official@gmail.com">
          timmy.cards.official@gmail.com
        </a>
      </Text>
    </div>
  );
};

export default Support;
