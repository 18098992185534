import { collection, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { db, obtainUserDoc, obtainUserPrivateDoc } from "../firebase";

const getAllUsersDoc = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));

  let userDocs = [];
  querySnapshot.forEach((doc) =>
    userDocs.push({ uid: doc.id, doc: doc.data() })
  );

  return userDocs;
};

const getUserDoc = async (user) => {
  const userDocRef = obtainUserDoc(user);
  const userDocSnap = await getDoc(userDocRef);

  return userDocSnap;
};

const getUserPrivateDoc = async (user) => {
  const userPrivateDocRef = obtainUserPrivateDoc(user);
  const userPrivateDocSnap = await getDoc(userPrivateDocRef);

  return userPrivateDocSnap;
};

const updateUserDoc = async (user, updatedDoc) => {
  await updateDoc(obtainUserDoc(user), updatedDoc);
};

const updateUserPrivateDoc = async (user, updatedDoc) => {
  await updateDoc(obtainUserPrivateDoc(user), updatedDoc);
};

const checkAdditionalInfo = async (user) => {
  const docRef = obtainUserPrivateDoc(user);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if (docSnap.data().hasOwnProperty("phoneNumber")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const userService = {
  getAllUsersDoc,
  getUserDoc,
  getUserPrivateDoc,
  updateUserDoc,
  updateUserPrivateDoc,
  checkAdditionalInfo,
};

export default userService;
