import { Title } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import PastTournamentsList from "../../components/tournaments/PastTournamentsList";

const PastTournaments = ({ userDoc }) => {
  return (
    <div className="page-div">
      {userDoc?.role !== "admin" ? null : (
        <a className="basic-anchor" href="/edit/tournaments/all">
          <IconEdit
            // onClick={() => (window.location.href = "/edit/tournaments/all")}
            className="admin-new-btn"
          />
        </a>
      )}
      <Title className="page-title">Past PTCG Tournaments in Malaysia</Title>
      <PastTournamentsList />
    </div>
  );
};

export default PastTournaments;
