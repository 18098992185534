import { List, Loader, Text } from "@mantine/core";
import "../AdminForm.css";

const NoSpriteList = ({ cards, handleUidClick, searchRes }) => {
  switch (searchRes) {
    case "loading":
      return <Loader />;
    case "none":
      return <Text>All cards in decks have been linked to sprites</Text>;
    case "found":
      return cards?.map((card, i) => (
        <List.Item
          key={i}
          onClick={() => handleUidClick(card)}
          className="new-add-link"
        >
          {card.uid}
        </List.Item>
      ));
    default:
      return null;
  }
};

export default NoSpriteList;
