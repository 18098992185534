import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import contentService from "../../services/content";
import CardInfo from "../../components/cards/CardInfo";
import "./Card.css";
import { Group } from "@mantine/core";
import CardSkills from "../../components/cards/CardSkills";
import { IconCopy } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
// import { NativeAds } from "../../components/ads/Adsterra/Ads";

const Card = ({ userDoc }) => {
  //fetch card id
  const uid = useParams().id;

  const [card, setCard] = useState([]);

  //fetch card data
  useEffect(() => {
    try {
      const params = {
        card_type: "pokemon",
        language: "en",
      };

      contentService
        .get({ route: `cards/${uid}`, params: params })
        .then((res) => setCard(res));
    } catch (err) {
      console.error(err.message);
    }
  }, [uid]);

  const copyBtnStyle = {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
  };

  return (
    <div className="page-div">
      {userDoc?.role === "admin" ? (
        <IconCopy
          style={copyBtnStyle}
          onClick={() => {
            navigator.clipboard.writeText(uid);
            notifications.show({
              title: `Copied`,
              message: `${card?.name}'s id (${uid}) has been copied`,
              color: "grey",
            });
          }}
        />
      ) : null}
      <div className="card-div">
        <Group justify="center" className="card-grp top" gap="0">
          <div className="card-img-div">
            <img
              src={card?.images}
              alt="card"
              className="card-img"
              onClick={() =>
                window.open(card?.images, card?.name, "height=750,width=1050")
              }
            />
          </div>
          <CardInfo card={card} />
        </Group>

        <Group justify="center" className="card-grp btm" gap="0">
          <CardSkills card={card} />
        </Group>
      </div>
      {/* <NativeAds /> */}
    </div>
  );
};

export default Card;
