const gamesLabel = (game) => {
  switch (game) {
    case "PKM":
      return "Pokemon";
    case "DGM":
      return "Digimon";
    case "YGO":
      return "Yu-Gi-Oh";
    case "OP":
      return "One Piece";
    case "MTG":
      return "Magic the Gathering";
    case "F&B":
      return "Flesh & Blood";
    case "VG":
      return "Vanguard";
    case "WS":
      return "Weiss Schwarz";
    case "DM":
      return "Duel Masters";
    case "BD":
      return "Build Divide";
    case "BS":
      return "Battle Spirit";
    case "SV":
      return "Shadowverse";
    case "D&D":
      return "Dungeons & Dragons";
    case "GA":
      return "Grand Archive";
    case "40K":
      return "Warhammer";
    case "Board":
      return "Board Games";
    default:
      return "";
  }
};

export default gamesLabel;
