import { useEffect, useRef, useState } from "react";
import contentService from "../../services/content";
import ArticlesCard from "./ArticlesCard";
import { Group } from "@mantine/core";

import "./AllBlogs.css";

const AllBlogs = () => {
  const [articleList, setArticleList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [page, setPage] = useState(1);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const lastItemRef = useRef(null);

  let allArticles = [];

  //get latest articles and render them
  useEffect(() => {
    const fetchArticleList = () => {
      contentService
        .get({ route: "articles/get-latest", params: { page: page } })
        .then((res) => {
          if (res?.length > 0) {
            setArticleList([...articleList, ...res]);
            setLastLoadedPage(lastLoadedPage + 1);
            setIsLoaded(true);
          } else {
            setFullyLoaded(true);
          }
        });
    };

    if (lastLoadedPage < page) {
      fetchArticleList();
    }
  }, [page, articleList, lastLoadedPage, setIsLoaded]);

  // observer for last item
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (isLoaded && entry.isIntersecting && !fullyLoaded) {
          setPage((page) => page + 1);
          setIsLoaded(false);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.7,
      }
    );
    // set target ref
    const currentTarget = lastItemRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [isLoaded, lastItemRef, setPage, setIsLoaded, fullyLoaded]);

  //render article list
  articleList.forEach((article, i) => {
    let articleCard = <ArticlesCard article={article} key={i} />;
    allArticles.push(articleCard);
  });

  return (
    <div>
      <Group className="blogs-card-group" justify="space-between" gap="xs">
        {allArticles}
      </Group>
      <div ref={lastItemRef}></div>
    </div>
  );
};

export default AllBlogs;
