import {
  IconBrandDiscordFilled,
  IconBrandFacebookFilled,
  IconBrandInstagram,
} from "@tabler/icons-react";

import "./Footer.css";
import CallBackend from "../../functions/adhoc/CallBackend";

const Footer = () => {
  return (
    <div className="footer-div">
      <div id="footer-content">
        <IconBrandDiscordFilled
          id="footer-disc-icon"
          className="footer-icon"
          onClick={() => window.open("https://discord.gg/rVvK2gaYU9", "_blank")}
        />
        <IconBrandFacebookFilled
          id="footer-fb-icon"
          className="footer-icon"
          onClick={() =>
            window.open("https://www.facebook.com/timmycards.os", "_blank")
          }
        />
        <IconBrandInstagram
          id="footer-ig-icon"
          className="footer-icon"
          onClick={() =>
            window.open("https://www.instagram.com/timmy_cards", "_blank")
          }
        />
        <p className="footer-text">
          All text literal, graphical images on this website about respective
          trading card game, including card images and texts, belong to their
          respective owners, including Pokémon Trading Card Game, Digimon Card
          Game, Yu-Gi-Oh! and all other tcg card games. TimmyCards is not, in
          any form, endorsed, supported or affiliated with any of the companies.
        </p>
        <a className="footer-policy" href="/policy">
          Privacy Policy
        </a>
        <p className="footer-text">
          Copyright © Timmy Cards {new Date().getFullYear()}
        </p>
        <CallBackend />
      </div>
    </div>
  );
};

export default Footer;
